<template>
  <h1>Modifica Referente</h1>

  <el-form :model="referente" label-width="240px">
    <el-form-item label="Nome">
      <el-input v-model="referente.nome" />
    </el-form-item>
    <el-form-item label="Cognome">
      <el-input v-model="referente.cognome" />
    </el-form-item>
    <el-form-item label="Mail">
      <el-input v-model="referente.mail" />
    </el-form-item>
    <el-form-item label="Telefono Fisso">
      <el-input v-model="referente.fisso" />
    </el-form-item>
    <el-form-item label="Telefono">
      <el-input v-model="referente.cell" />
    </el-form-item>
    <el-form-item label="Note">
      <el-input v-model="referente.note" />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit"> Modifica </el-button>
      <el-button @click="annulla"> Annulla </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { reactive, toRaw, onMounted } from 'vue';
import detailsAPI from '../../services/detailsAPI';

export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const route = useRoute();
    const proprietarioId = route.params.id;
    const referenteId = route.params.referenteId;

    const referente = reactive({
      id: 0,
      nome: '',
      cognome: '',
      mail: '',
      fisso: '',
      cell: '',
      note: '',
      fkProprietario: proprietarioId,
    });

    //Inizializzo i pulsanti
    const onSubmit = () => {
      editRef(referenteId, toRaw(referente)).then((response) => {
        if (response.status === 200) {
          console.log('Referente modificato!');
          router.push(`/details/${proprietarioId}`);
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const annulla = () => {
      router.push(`/details/${proprietarioId}`);
    };

    // Funzioni per aggiungere nuovo
    const editRef = async (referenteId, referente) => {
      let response = {};
      try {
        response = await detailsAPI.patchReferente(referenteId, referente);
        return response;
      } catch {
        console.log('error');
      }
    };

    //Riempio una volta caricata la pagina
    const getRef = async (id) => {
      let response = {};
      try {
        response = await detailsAPI.getReferente(id);
        return response.data;
      } catch {
        console.log('error');
      }
    };

    onMounted(() => {
      getRef(referenteId).then((referenteDetail) => {
        if (referenteDetail) {
          Object.assign(referente, referenteDetail);
        }
      });
    });

    return {
      proprietarioId,
      referente,
      onSubmit,
      annulla,
    };
  },
};
</script>
