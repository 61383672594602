import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

export const isLoggedStore = defineStore('logged', () => {
  const isLogged = useStorage('isLogged', false);
  const nome = useStorage('nome', '');
  const tipo = useStorage('tipo', '');
  const user = useStorage('user', '');
  const viewMenu = useStorage('viewMenu', false);
  return { isLogged, nome, tipo, user, viewMenu };
});
