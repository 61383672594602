<template>
  <h1>Prestazioni da Fatturare</h1>
  <el-table :data="items" style="width: 100%">
    <el-table-column prop="description" label="Descrizione" />
    <el-table-column prop="number" label="Quantità"> </el-table-column>
    <el-table-column prop="price" label="Prezzo"> </el-table-column>
    <el-table-column label="Totale">
      <template #default="secondScope">
        €
        {{
          (
            items[secondScope.$index].price * items[secondScope.$index].number
          ).toFixed(2)
        }}
      </template>
    </el-table-column>
  </el-table>
  <el-row style="margin-top: 20px">
    <el-col :offset="18" :span="3" justify="start"
      ><b
        >Totale: €
        {{
          (+items.reduce(
            (partialSum, item) => partialSum + item.price * item.number,
            0
          )).toFixed(2)
        }}</b
      ></el-col
    >
  </el-row>

  <h2>Note</h2>
  <p>{{ note }}</p>
  <br />
  <br />
  <br />
  <h2>Numero di Fattura</h2>
  <el-input-number v-model="numeroFattura" :step="1" />
  <br />
  <br />
  <br />
  <el-button type="success" plain @click="send"
    >Contrassegna come fatturato</el-button
  >
  <el-button @click="turnBack">Indietro</el-button>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import detailsAPI from '@/services/detailsAPI';
import { ref } from 'vue';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const id = route.params.id;
    let numeroFattura = ref(0);
    let note = ref('');
    let items = ref([]);
    let worksheetId = ref(0);

    const loadPreventivo = async () => {
      let preventivo = (await detailsAPI.getPreventivo(id)).data;
      console.log(preventivo);
      note.value = preventivo.note;
      preventivo.items.forEach((item) => {
        items.value.push(item);
      });
      worksheetId.value = preventivo.fkFoglioLavoro;
    };

    const send = async () => {
      const patch = await detailsAPI.patchWorksheet(worksheetId.value, {
        fatturato: 1,
        numeroFattura: numeroFattura.value,
      });
      if (patch.status == 200) {
        router.push('/administration-home');
      }
    };

    const turnBack = () => {
      router.push('/administration-home');
    };
    loadPreventivo();
    return {
      turnBack,
      items,
      note,
      numeroFattura,
      send,
    };
  },
};
</script>
