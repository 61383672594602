<template>
  <h1>Modifica {{ impianto.nomeImpianto }}</h1>

  <el-form :model="impianto" label-width="240px">
    <el-form-item label="Nome Impianto">
      <el-input v-model="impianto.nomeImpianto" />
    </el-form-item>
    <el-form-item label="Indirizzo IP">
      <el-input v-model="impianto.ip" />
    </el-form-item>
    <el-form-item label="Username">
      <el-input v-model="impianto.nomeuser" />
    </el-form-item>
    <el-form-item label="Password">
      <el-input v-model="impianto.password" />
    </el-form-item>
    <el-form-item label="GSE">
      <el-input v-model="impianto.gse" />
    </el-form-item>
    <el-form-item label="GSE Incentivi">
      <el-input v-model="impianto.gseIncentivi" />
    </el-form-item>
    <el-form-item label="GSE Vendita">
      <el-input v-model="impianto.gseVendita" />
    </el-form-item>

    <el-form-item label="CENSIMP">
      <el-input v-model="impianto.censimp" />
    </el-form-item>
    <el-form-item label="POD">
      <el-input v-model="impianto.pod" />
    </el-form-item>
    <el-form-item label="DITTA">
      <el-input v-model="impianto.ditta" />
    </el-form-item>
    <el-form-item label="Note">
      <el-input v-model="impianto.note" type="textarea" />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit"> Modifica </el-button>
      <el-button @click="annulla"> Annulla </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, reactive, toRaw } from 'vue';
import detailsAPI from '../../services/detailsAPI';

export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const route = useRoute();
    const proprietarioId = route.params.id;
    const impiantoId = route.params.impiantiId;

    const impianto = reactive({
      id: 0,
      nomeImpianto: '',
      ip: '',
      nomeuser: '',
      password: '',
      gse: '',
      gseIncentivi: '',
      gseVendita: '',
      censimp: '',
      pod: '',
      ditta: '',
      note: '',
      fkCentrale: 0,
    });

    //Inizializzo i pulsanti
    const onSubmit = () => {
      editImpianto(impiantoId, toRaw(impianto)).then((response) => {
        if (response.status === 200) {
          console.log('Impianto modificato!');
          router.push(`/details/${proprietarioId}`);
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const annulla = () => {
      router.push(`/details/${proprietarioId}`);
    };

    //Riempio una volta xaricata la pagina
    const getImpianto = async (id) => {
      let response = {};
      try {
        console.log(id);
        response = await detailsAPI.getImpianto(id);
        return response.data;
      } catch {
        console.log('error');
      }
    };

    onMounted(() => {
      getImpianto(impiantoId).then((impiantoDetail) => {
        if (impiantoDetail) {
          Object.assign(impianto, impiantoDetail);
          console.log(impianto);
        }
      });
    });

    // Funzioni per modificare
    const editImpianto = async (id, impianto) => {
      let response = {};
      try {
        response = await detailsAPI.patchImpianto(id, impianto);
        return response;
      } catch {
        console.log('error');
      }
    };

    return {
      proprietarioId,
      impianto,
      onSubmit,
      annulla,
    };
  },
};
</script>
