<template>
  <ProprietarioRead v-if="details" :proprietario="details" />
</template>

<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import detailsApi from '../services/detailsAPI';
import ProprietarioRead from '@/components/ProprietarioRead.vue';

export default {
  components: { ProprietarioRead },
  setup() {
    let details = ref({});
    const route = useRoute();

    const loadDetails = async (id) => {
      let response = {};
      try {
        response = await detailsApi.getDetails(id);
        return response.data;
      } catch {
        console.log('error');
      }
    };

    loadDetails(route.params.id).then((x) => {
      details.value = x;
    });

    return {
      details,
    };
  },
};
</script>
