<template>
  <h1 class="header-implant">Moduli</h1>

  <div v-if="moduli[selectedModule] !== undefined">
    <el-row :gutter="0">
      <el-col :span="4">
        <div class="grid-content" />
        <h4 style="text-align: left; margin-top: 0px">Seleziona Modulo</h4>
        <div id="selection" class="choose-section">
          <el-button
            v-for="(modulo, index) in moduli"
            :key="modulo.id"
            type="primary"
            class="choose-btn"
            :plain="!(selectedModule == index)"
            @click="selectModule(index)"
          >
            {{ modulo.marca + ' ' + modulo.modello }}
          </el-button>
        </div>
        <div class="action-btn">
          <router-link
            class="routerLink"
            :to="{
              path: `/details/${proprietarioId}/inverter/${inverterId}/moduli/add`,
            }"
          >
            <el-button type="success" plain class="choose-btn">
              Aggiungi
            </el-button>
          </router-link>
          <router-link
            class="routerLink"
            :to="{
              path: `/details/${proprietarioId}/inverter/${inverterId}/moduli/${moduli[selectedModule].id}/edit`,
            }"
          >
            <el-button type="info" plain class="choose-btn">
              Modifica
            </el-button>
          </router-link>
        </div>
      </el-col>
      <el-col :span="20">
        <el-descriptions title="Dettagli Modulo" :column="3" border>
          <el-descriptions-item label="Marca" label-align="right" align="left">
            {{ moduli[selectedModule].marca }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Modello"
            label-align="right"
            align="left"
          >
            {{ moduli[selectedModule].modello }}
          </el-descriptions-item>
          <el-descriptions-item label="Numero" label-align="right" align="left">
            {{ moduli[selectedModule].numero }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Potenza Nominale"
            label-align="right"
            align="left"
          >
            {{ moduli[selectedModule].potenza }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Potenza Totale"
            label-align="right"
            align="left"
            :span="2"
          >
            {{ moduli[selectedModule].numero * moduli[selectedModule].potenza /1000 }}
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>
  </div>
  <div v-else>
    <router-link
      class="routerLink"
      :to="{
        path: `/details/${proprietarioId}/inverter/${inverterId}/moduli/add`,
      }"
    >
      <el-button type="success" plain class="choose-btn">
        Aggiungi Moduli
      </el-button>
    </router-link>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'ModuliRead',
  components: {},
  props: {
    moduli: { type: Array, default: () => {} },
    inverterId: { type: Number, default: () => {} },
  },
  setup() {
    let route = useRoute();
    const proprietarioId = route.params.id;
    let selectedModule = ref(0);
    let selectModule = (index) => {
      selectedModule.value = index;
    };

    return {
      selectedModule,
      selectModule,
      proprietarioId,
    };
  },
  computed: {},
};
</script>

<style></style>
