<template>
  <h1>Nuovo Referente</h1>

  <el-form :model="referente" label-width="240px">
    <el-form-item label="Nome">
      <el-input v-model="referente.nome" />
    </el-form-item>
    <el-form-item label="Cognome">
      <el-input v-model="referente.cognome" />
    </el-form-item>
    <el-form-item label="Mail">
      <el-input v-model="referente.mail" />
    </el-form-item>
    <el-form-item label="Telefono Fisso">
      <el-input v-model="referente.fisso" />
    </el-form-item>
    <el-form-item label="Telefono">
      <el-input v-model="referente.cell" />
    </el-form-item>
    <el-form-item label="Note">
      <el-input v-model="referente.note" />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit"> Aggiungi </el-button>
      <el-button @click="annulla"> Annulla </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { reactive, toRaw } from 'vue';
import detailsAPI from '../../services/detailsAPI';
import { ElMessage } from 'element-plus';
export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const route = useRoute();
    const proprietarioId = route.params.id;

    const referente = reactive({
      nome: '',
      cognome: '',
      mail: '',
      fisso: '',
      cell: '',
      fkProprietario: proprietarioId,
      note: '',
    });

    //Inizializzo i pulsanti
    const onSubmit = () => {
      if (referente.nome == '' || referente.cognome == '') {
        ElMessage({
          message: 'Inserire sia nome che cognome Referente',
          type: 'warning',
        });
        return;
      }
      addNewRef(toRaw(referente)).then((response) => {
        console.log(response.data);
        if (response.status === 201) {
          console.log('Referente aggiunto!');
          router.push(`/details/${proprietarioId}`);
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const annulla = () => {
      router.push(`/details/${proprietarioId}`);
    };

    // Funzioni per aggiungere nuovo
    const addNewRef = async (referente) => {
      let response = {};
      try {
        response = await detailsAPI.addReferente(referente);
        return response;
      } catch {
        console.log('error');
      }
    };

    return {
      proprietarioId,
      referente,
      onSubmit,
      addNewRef,
      annulla,
    };
  },
};
</script>
