<template>
  <h1>Nuovo Proprietario</h1>

  <el-form :model="proprietario" label-width="240px">
    <!-- fake fields are a workaround for chrome autofill getting the wrong fields -->
    <input style="display: none" type="text" name="fakeusernameremembered" />
    <input
      style="display: none"
      type="password"
      name="fakepasswordremembered"
    />

    <h3>Dati Cliente</h3>
    <el-switch
      v-model="tipo"
      class="mb-2"
      active-text="Azienda"
      inactive-text="Privato"
    />
    <el-form-item label="Ragione Sociale" v-if="tipo">
      <el-input v-model="proprietario.ragione" />
    </el-form-item>
    <el-form-item label="Nome" v-if="!tipo">
      <el-input v-model="proprietario.nome" />
    </el-form-item>
    <el-form-item label="Cognome" v-if="!tipo">
      <el-input v-model="proprietario.cognome" />
    </el-form-item>

    <el-form-item label="Partita IVA" v-if="tipo">
      <el-input v-model="proprietario.piva" />
    </el-form-item>
    <el-form-item label="Codice Fiscale" v-if="!tipo">
      <el-input v-model="proprietario.cf" />
    </el-form-item>
    <el-form-item label="IBAN">
      <el-input v-model="proprietario.iban" />
    </el-form-item>
    <h3>Sede Legale</h3>
    <el-form-item label="Via">
      <el-input v-model="proprietario.viaSedelegale" />
    </el-form-item>
    <el-form-item label="Numero Civico">
      <el-input v-model="proprietario.numcivSedelegale" />
    </el-form-item>
    <el-form-item label="Comune">
      <el-input v-model="proprietario.comunSedelegale" />
    </el-form-item>
    <el-form-item label="Provincia">
      <el-input v-model="proprietario.provinSedelegale" />
    </el-form-item>
    <el-form-item label="CAP">
      <el-input v-model="proprietario.capSedelegale" />
    </el-form-item>
    <h3>Sede Operativa</h3>
    <el-form-item label="Via">
      <el-input v-model="proprietario.viaSedeoperativa" />
    </el-form-item>
    <el-form-item label="Numero Civico">
      <el-input v-model="proprietario.numcivSedeoperativa" />
    </el-form-item>
    <el-form-item label="Comune">
      <el-input v-model="proprietario.comunSedeoperativa" />
    </el-form-item>
    <el-form-item label="Provincia">
      <el-input v-model="proprietario.provinSedeoperativa" />
    </el-form-item>
    <el-form-item label="CAP">
      <el-input v-model="proprietario.capSedeoperativa" />
    </el-form-item>
    <el-button @click="copy">Copia da sede legale</el-button>
    <h3>GSE</h3>
    <el-form-item label="Username GSE">
      <el-input v-model="proprietario.userGse" />
    </el-form-item>
    <el-form-item label="Password GSE">
      <el-input
        type="password"
        placeholder="Inserisci password"
        show-password
        v-model="proprietario.passGse"
      />
    </el-form-item>
    <h3>Terna</h3>
    <el-form-item label="Username Terna">
      <el-input v-model="proprietario.userTerna" />
    </el-form-item>
    <el-form-item label="Password Terna">
      <el-input
        type="password"
        placeholder="Inserisci password"
        show-password
        v-model="proprietario.passTerna"
      />
    </el-form-item>
    <h3>Enel</h3>
    <el-form-item label="Username Enel">
      <el-input v-model="proprietario.userEnel" />
    </el-form-item>
    <el-form-item label="Password Enel">
      <el-input
        type="password"
        placeholder="Inserisci password"
        show-password
        v-model="proprietario.passEnel"
      />
    </el-form-item>
    <el-form-item label="Note">
      <el-input type="textarea" v-model="proprietario.codSicEnel" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> Aggiungi </el-button>
      <el-button @click="annulla"> Annulla </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRouter } from 'vue-router';
import { reactive, toRaw, ref } from 'vue';
import detailsAPI from '../../services/detailsAPI';
import { ElMessage } from 'element-plus';
export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();

    const tipo = ref(true);
    const proprietario = reactive({
      piva: '',
      cf: '',
      iban: '',
      viaSedelegale: '',
      numcivSedelegale: '',
      comunSedeoperativa: '',
      provinSedeoperativa: '',
      capSedeoperativa: '',
      userGse: '',
      passGse: '',
      userTerna: '',
      passTerna: '',
      userEnel: '',
      passEnel: '',
      codSicEnel: '',
      nome: '',
      cognome: '',
      comunSedelegale: '',
      provinSedelegale: '',
      capSedelegale: '',
      viaSedeoperativa: '',
      numcivSedeoperativa: '',
      ragione: '',
    });

    let copy = () => {
      proprietario.capSedeoperativa = proprietario.capSedelegale;
      proprietario.viaSedeoperativa = proprietario.viaSedelegale;
      proprietario.comunSedeoperativa = proprietario.comunSedelegale;
      proprietario.numcivSedeoperativa = proprietario.numcivSedelegale;
      proprietario.provinSedeoperativa = proprietario.provinSedelegale;
    };

    //Inizializzo i pulsanti
    const onSubmit = () => {
      if (
        proprietario.ragione == '' &&
        proprietario.nome == '' &&
        proprietario.cognome == ''
      ) {
        ElMessage({
          message:
            'Inserire almeno Ragione Sociale se Impresa o Nome e Cognome se privato ',
          type: 'warning',
        });
        return;
      }
      if (proprietario.cf == '') proprietario.cf = proprietario.piva;
      addNewProprietario(toRaw(proprietario)).then((response) => {
        console.log(response.data);
        if (response.status === 201) {
          console.log('Proprietario aggiunto!');
          router.push(`/`);
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const annulla = () => {
      router.push(`/`);
    };

    // Funzioni per aggiungere nuovo
    const addNewProprietario = async (proprietario) => {
      let response = {};
      try {
        response = await detailsAPI.addProprietario(proprietario);
        return response;
      } catch {
        console.log('error');
      }
    };

    return {
      proprietario,
      tipo,
      onSubmit,
      annulla,
      copy,
    };
  },
};
</script>
