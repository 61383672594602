<template>
  <h1>Nuovo Impianto</h1>

  <el-form :model="impianto" label-width="240px">
    <el-form-item label="Nome Impianto">
      <el-input v-model="impianto.nomeImpianto" />
    </el-form-item>
    <el-form-item label="Indirizzo IP">
      <el-input v-model="impianto.ip" />
    </el-form-item>
    <el-form-item label="Username">
      <el-input v-model="impianto.nomeuser" />
    </el-form-item>
    <el-form-item label="Password">
      <el-input v-model="impianto.password" />
    </el-form-item>
    <el-form-item label="GSE">
      <el-input v-model="impianto.gse" />
    </el-form-item>
    <el-form-item label="GSE Incentivi">
      <el-input v-model="impianto.gseIncentivi" />
    </el-form-item>
    <el-form-item label="GSE Vendita">
      <el-input v-model="impianto.gseVendita" />
    </el-form-item>

    <el-form-item label="CENSIMP">
      <el-input v-model="impianto.censimp" />
    </el-form-item>
    <el-form-item label="POD">
      <el-input v-model="impianto.pod" />
    </el-form-item>
    <el-form-item label="DITTA">
      <el-input v-model="impianto.ditta" />
    </el-form-item>
    <el-form-item label="Note">
      <el-input v-model="impianto.note" type="textarea" />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit"> Aggiungi </el-button>
      <el-button @click="annulla"> Annulla </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { reactive, toRaw } from 'vue';
import detailsAPI from '../../services/detailsAPI';
import { ElMessage } from 'element-plus';
export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const route = useRoute();
    const centraleId = route.params.centraleId;
    const proprietarioId = route.params.id;

    const impianto = reactive({
      nomeImpianto: '',
      ip: '',
      nomeuser: '',
      password: '',
      gse: '',
      gseIncentivi: '',
      gseVendita: '',
      censimp: '',
      pod: '',
      ditta: '',
      note: '',
      fkCentrale: centraleId,
    });

    //Inizializzo i pulsanti
    const onSubmit = () => {
      if (impianto.nomeImpianto == '') {
        ElMessage({ message: 'Inserire un nome impianto', type: 'warning' });
        return;
      }
      addNewImpianto(toRaw(impianto)).then((response) => {
        console.log(response);
        if (response.status === 201) {
          console.log('Impianto aggiunto!');
          router.push(`/details/${proprietarioId}`);
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const annulla = () => {
      router.push(`/details/${proprietarioId}`);
    };

    // Funzioni per aggiungere nuovo
    const addNewImpianto = async (impianto) => {
      console.log(impianto);
      let response = {};
      try {
        response = await detailsAPI.addImpianto(impianto);
        console.log(response);
        return response;
      } catch {
        console.log('error');
      }
    };

    return {
      proprietarioId,
      impianto,
      onSubmit,
      addNewImpianto,
      annulla,
    };
  },
};
</script>
