import API from './API';

export default {
  filterGears(filterValue) {
    return API().post('/proprietario/filter-gears', filterValue);
  },

  login(loginData) {
    return API().post('/user/login', loginData);
  },
  getOperatorList() {
    return API().get('/user/workers');
  },

  getCustomerList() {
    return API().get('/proprietario/short-list');
  },

  filterWorksheet(options) {
    return API().post('/worksheet/filter', options);
  },

  filterContract(filter) {
    return API().post('/contratto/filter', filter);
  },
  getCustomers() {
    return API().get('/proprietario');
  },
  getScadenze() {
    return API().get('/proprietario/scadenze');
  },
  getAutocompleteModule() {
    return API().get('/modulo/autocomplete');
  },
  getAutocompleteInverter() {
    return API().get('/inverter/autocomplete');
  },
  getAutocompleteSezione() {
    return API().get('/sezione/autocomplete');
  },
};
