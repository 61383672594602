<template>
  <h1>Nuovo Contratto</h1>

  <el-form :model="contratto" label-width="240px">
    <el-form-item label="Nome Contratto">
      <el-input v-model="contratto.nome" />
    </el-form-item>
    <el-form-item label="Upload PDF">
      <input type="file" @change="onFileSelected" />
    </el-form-item>

    <el-form-item label="Manutenzione Ordinaria">
      <el-input-number v-model="contratto.manutenzioneOrdinaria" />
    </el-form-item>
    <el-form-item label="Cliente senza contratto">
      <el-switch v-model="contratto.clientesenzacontratto" />
    </el-form-item>
    <el-form-item label="Assistenza Amministrativa">
      <el-switch v-model="contratto.assistenzaAmm" />
    </el-form-item>
    <el-form-item label="Telecontrollo">
      <el-switch v-model="contratto.telecontrollo" />
    </el-form-item>
    <el-form-item label="Manutenzione Straordinaria">
      <el-switch v-model="contratto.straordinaria" />
    </el-form-item>
    <el-form-item label="Lavaggio Moduli">
      <el-switch v-model="contratto.lavaggioModuli" />
    </el-form-item>
    <el-form-item label="Dichiarazioni di Consumo">
      <el-switch v-model="contratto.dichiarazionidiconsumo" />
    </el-form-item>
    <el-form-item label="Taglio Erba">
      <el-switch v-model="contratto.taglioerba" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> Aggiungi </el-button>
      <el-button @click="annulla"> Annulla </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { reactive, toRaw } from 'vue';
import detailsAPI from '../../services/detailsAPI';
import { ElMessage } from 'element-plus';
export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const route = useRoute();
    const proprietarioId = route.params.id;

    const contratto = reactive({
      nome: '',
      pdf: '',
      manutenzioneOrdinaria: 0,
      clientesenzacontratto: false,
      assistenzaAmm: false,
      telecontrollo: false,
      straordinaria: false,
      lavaggioModuli: false,
      dichiarazionidiconsumo: false,
      taglioerba: false,
      fkProprietario: proprietarioId,
    });
    const convertBooleanFields = (inputObj) => {
  // Helper function to perform the conversion
  function convert(obj) {
    let newObj = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'boolean') {
          newObj[key] = obj[key] ? 1 : 0;
        } else if (typeof obj[key] === 'object' && obj[key] !== null && !(obj[key] instanceof Date)) {
          newObj[key] = convert(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
    return newObj;
  }

  // Return the converted object
  return convert(inputObj);
}

    //Inizializzo i pulsanti
    const onSubmit = () => {
      if (contratto.nome == '') {
        ElMessage({ message: 'Inserire un nome contratto', type: 'warning' });
        return;
      }
      if (selectedFile) {
        let fd = new FormData();
        fd.append('pdf', selectedFile, selectedFile.name);
        try {
          detailsAPI.upload(fd).then((resp) => {
            if (resp.status == 201) {
              contratto.pdf = resp.data;
              addNewContract(toRaw(contratto)).then((response) => {
                console.log(response.data);
                if (response.status === 201) {
                  console.log('Contratto aggiunto!');
                  router.push(`/details/${proprietarioId}`);
                } else {
                  console.log('errore inatteso');
                }
              });
            }
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        addNewContract(toRaw(contratto)).then((response) => {
          console.log(response.data);
          if (response.status === 201) {
            console.log('Contratto aggiunto!');
            router.push(`/details/${proprietarioId}`);
          } else {
            console.log('errore inatteso');
          }
        });
      }
    };

    //upload
    let selectedFile = null;
    const onFileSelected = (e) => {
      selectedFile = e.target.files[0];
    };

    const annulla = () => {
      router.push(`/details/${proprietarioId}`);
    };

    // Funzioni per aggiungere nuovo
    const addNewContract = async (contratto) => {
      let response = {};
      try {
        let contratto2 = convertBooleanFields(contratto)

        response = await detailsAPI.addContract(contratto2);
        return response;
      } catch {
        console.log('error');
      }
    };

    return {
      proprietarioId,
      contratto,
      onSubmit,
      onFileSelected,
      addNewContract,
      annulla,
    };
  },
};
</script>
