<template>
  <h1>Ciao {{ nome_segretaria }}</h1>
  <el-table :data="worksheets" max-height="500" style="width: 100%">
    <el-table-column prop="proprietario" label="Cliente" />
    <el-table-column prop="tecnico" label="Tecnico" />
    <el-table-column prop="data" label="Data" />
    <el-table-column label="Tipo di intervento">
      <template #default="id">
        <el-tag v-if="id.row.ordinario == 1">Manutenzione Ordinaria</el-tag>
        <el-tag v-if="id.row.straordinario == 1"
          >Manutenzione Straordinaria</el-tag
        >
        <el-tag v-if="id.row.cantiereOem == 1">Cantiere Oem</el-tag>
        <el-tag v-if="id.row.garanzia == 1">Garanzia</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="Fogli Lavoro">
      <template #default="test">
        <el-button size="small" @click="seeWorksheets(test.row.id)">
          Vedi
        </el-button>
      </template>
    </el-table-column>
    <el-table-column label="Fattura">
      <template #default="scope">
        <el-button
          v-if="scope.row.straordinario == 0"
          size="small"
          type="success"
          plain
          @click="fatturato(scope.$index, scope.row.id)"
        >
          Archivia
        </el-button>

        <el-button
          v-if="scope.row.straordinario == 1"
          size="small"
          type="success"
          plain
          @click="fatturatoStraordinario(scope.row.preventivi[0].id)"
        >
          Fattura
        </el-button>
      </template>
    </el-table-column></el-table
  >
</template>

<script>
import { isLoggedStore } from '@/store/logged';
import { ref } from 'vue';
import detailsAPI from '../../services/detailsAPI';
import { useRouter } from 'vue-router';

export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const store = isLoggedStore();
    const nome_segretaria = store.nome;
    const router = useRouter();
    let worksheets = ref([]);

    const loadNotInvoicedWorksheet = async () => {
      let notInvoicedWorksheet = (await detailsAPI.loadNotInvoicedWorksheet())
        .data;
      for (let worksheet of notInvoicedWorksheet) {
        worksheets.value.push({
          id: worksheet.id,
          data: new Date(worksheet.date).toLocaleDateString('IT-it'),
          tecnico: worksheet.fkUser.nome,
          proprietario: worksheet.proprietario,
          ordinario: worksheet.manutenzioneOrdinaria,
          straordinario: worksheet.manutenzioneStraordinaria,
          cantiere: worksheet.cantiereOem,
          preventivi: worksheet.preventivi,
          garanzia: worksheet.garanzia,
        });
      }
    };

    const seeWorksheets = async (id) => {
      await detailsAPI.printWorksheet(id);
    };

    const fatturato = async (index, id) => {
      const resp = await detailsAPI.invoiceWorksheet(id);
      if (resp.status === 200) {
        worksheets.value.splice(index, 1);
      } else {
        console.log('error');
      }
    };

    const fatturatoStraordinario = async (id) => {
      router.push('/fattura-straordinario/' + id);
    };

    const openPriceCalculator = (id) => {
      router.push('/price-calculator/' + id);
      console.log(id);
    };

    loadNotInvoicedWorksheet();
    return {
      nome_segretaria,
      fatturatoStraordinario,
      seeWorksheets,
      worksheets,
      fatturato,
      openPriceCalculator,
    };
  },
};
</script>
