<template>
  <h1>Modifica {{ modulo.marca }} {{ modulo.modello }}</h1>

  <el-form
    :model="modulo"
    label-width="240px"
  >
    <el-form-item label="Marca">
      <el-input v-model="modulo.marca" />
    </el-form-item>
    <el-form-item label="Modello">
      <el-input v-model="modulo.modello" />
    </el-form-item>
    <el-form-item label="Numero">
      <el-input-number v-model="modulo.numero" />
    </el-form-item>
    <el-form-item label="Potenza">
      <el-input-number v-model="modulo.potenza" />
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
      >
        Modifica
      </el-button>
      <el-button @click="annulla">
        Annulla
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, reactive, toRaw } from 'vue';
import detailsAPI from '../../services/detailsAPI';

export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const route = useRoute();
    const proprietarioId = route.params.id;
    const moduloId = route.params.moduloId;

    const modulo = reactive({
      id: 0,
      nome: '',
      marca: '',
      potenza: 0,
      numero: 0,
      modello: '',
      matricola: '',
      fkInverter: 0,
    });

    //Inizializzo i pulsanti
    const onSubmit = () => {
      editModulo(moduloId, toRaw(modulo)).then((response) => {
        if (response.status === 200) {
          router.push(`/details/${proprietarioId}`);
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const annulla = () => {
      router.push(`/details/${proprietarioId}`);
    };

    //Riempio una volta caricata la pagina
    const getModulo = async (id) => {
      let response = {};
      try {
        response = await detailsAPI.getModulo(id);
        return response.data;
      } catch {
        console.log('error');
      }
    };

    onMounted(() => {
      getModulo(moduloId).then((moduloDetail) => {
        if (moduloDetail) {
          Object.assign(modulo, moduloDetail);
        }
      });
    });

    // Funzioni per modificare
    const editModulo = async (id, modulo) => {
      let response = {};
      try {
        response = await detailsAPI.patchModulo(id, modulo);
        return response;
      } catch {
        console.log('error');
      }
    };

    return {
      proprietarioId,
      modulo,
      onSubmit,
      annulla,
    };
  },
};
</script>
