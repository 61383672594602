<template>
  <h1>Calcola Prezzo</h1>
  <h2>Ore di Lavoro</h2>
  <el-table :data="items" style="width: 100%">
    <el-table-column prop="description" label="Descrizione" />
    <el-table-column prop="operatore" label="Operatore" />
    <el-table-column prop="quantity" label="Quantità">
      <template #default="id">
        <el-input-number
          v-model="items[id.$index].quantity"
          :precision="1"
          :step="0.5"
        />
      </template>
    </el-table-column>
    <el-table-column prop="price" label="Prezzo">
      <template #default="scope">
        <el-input-number
          v-model="items[scope.$index].price"
          :precision="2"
          :step="0.1"
        />
      </template>
    </el-table-column>
    <el-table-column label="Totale">
      <template #default="secondScope">
        €
        {{
          (
            items[secondScope.$index].price * items[secondScope.$index].quantity
          ).toFixed(2)
        }}
      </template>
    </el-table-column>
  </el-table>

  <h2>Pezzi</h2>
  <div v-if="chunks.length > 0">
    <el-table :data="chunks" style="width: 100%">
      <el-table-column prop="description" label="Descrizione" />
      <el-table-column prop="tipo" label="Marca e modello" />
      <el-table-column prop="quantity" label="Quantità">
        <template #default="scopeQuantity">
          <el-input-number
            v-model="chunks[scopeQuantity.$index].quantity"
            :precision="0"
            :step="1"
          />
        </template>
      </el-table-column>
      <el-table-column prop="price" label="Prezzo">
        <template #default="scopePrice">
          <el-input-number
            v-model="chunks[scopePrice.$index].price"
            :precision="2"
            :step="0.1"
          />
        </template>
      </el-table-column>
      <el-table-column label="Totale">
        <template #default="secondScopePrice">
          €
          {{
            (
              chunks[secondScopePrice.$index].price *
              chunks[secondScopePrice.$index].quantity
            ).toFixed(2)
          }}
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-row style="margin-top: 20px">
    <el-col :offset="18" :span="3" justify="start"
      ><b
        >Totale: €
        {{
          (
            +items.reduce(
              (partialSum, item) => partialSum + item.price * item.quantity,
              0
            ) +
            +chunks.reduce(
              (partialSum, item) => partialSum + item.price * item.quantity,
              0
            )
          ).toFixed(2)
        }}</b
      ></el-col
    >
  </el-row>
  <h2>Note</h2>
  <el-input
    v-model="note"
    :rows="3"
    type="textarea"
    placeholder="Inserisci delle note per la segreteria"
  />
  <br />
  <br />
  <br />
  <el-button type="success" plain @click="send">Invia in segreteria</el-button>
  <el-button @click="turnBack">Indietro</el-button>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import { ref } from 'vue';
import detailsAPI from '@/services/detailsAPI';
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const id = route.params.id;
    let items = ref([
      {
        description: 'Diritto di chiamata',
        price: 75.0,
        quantity: 1,
        operatore: '//',
      },
    ]);
    let note = ref('');

    let chunks = ref([]);

    const loadItems = async (id) => {
      const priceItems = await detailsAPI.loadPriceItems(id);
      for (let price of priceItems.data.hours) {
        items.value.push({
          description: 'Ore Standard Lavorate',
          operatore: price.tecnico,
          quantity: price.hours.regular,
          price: 40.0,
        });
        items.value.push({
          description: 'Ore Extra Lavorate',
          operatore: price.tecnico,
          quantity: price.hours.extra,
          price: 60.0,
        });
      }
      for (let chunk of priceItems.data.chunks) {
        chunks.value.push({
          description: chunk.descrizione,
          tipo: chunk.tipo,
          quantity: chunk.numero,
          price: 0.0,
        });
      }
    };

    const send = async () => {
      console.log('inviato in segreteria');
      let preventivo = {
        note: note.value,
        items: [],
        fkFoglioLavoro: id,
      };

      items.value.forEach((orario) => {
        preventivo.items.push({
          description: orario.description + ' ' + orario.operatore,
          number: orario.quantity,
          price: orario.price,
        });
      });

      chunks.value.forEach((chunk) => {
        preventivo.items.push({
          description: chunk.description + ' ' + chunk.tipo,
          number: chunk.quantity,
          price: chunk.price,
        });
      });

      const resp = await detailsAPI.addPreventivo(preventivo);
      if (resp.status == 201) {
        const patch = await detailsAPI.patchWorksheet(id, { inviato: 2 });
        if (patch.status == 200) {
          router.push('/aggiungi-preventivo');
        }
      }
    };
    const turnBack = () => {
      router.push('/aggiungi-preventivo');
    };

    loadItems(id);
    return {
      turnBack,
      items,
      chunks,
      note,
      send,
    };
  },
};
</script>
