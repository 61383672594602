<template>
  <div v-if="proprietario !== undefined">
    <el-descriptions title="Dettagli proprietario" :column="3" border>
      <el-descriptions-item
        label="Denominazione"
        label-align="right"
        align="left"
        label-class-name="my-label"
        class-name="my-content"
        :span="2"
      >
        {{
          proprietario.ragione + proprietario.nome + ' ' + proprietario.cognome
        }}
      </el-descriptions-item>
      <el-descriptions-item label="Iban" label-align="right" align="left">
        {{ proprietario.iban }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Partita Iva"
        label-align="right"
        align="left"
      >
        {{ proprietario.piva }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Codice Fiscale"
        label-align="right"
        align="left"
      >
        {{ proprietario.cf }}
      </el-descriptions-item>
      <el-descriptions-item label="PDF" label-align="right" align="left">
        <el-tag size="small"> Apri </el-tag>
      </el-descriptions-item>

      <el-descriptions-item
        label="Sede operativa"
        label-align="right"
        :span="2"
        align="left"
        label-class-name="my-label"
        class-name="my-content"
      >
        {{
          proprietario.viaSedeoperativa +
          ', ' +
          proprietario.numcivSedeoperativa +
          ' ' +
          proprietario.capSedeoperativa +
          ' ' +
          proprietario.comunSedeoperativa +
          ' (' +
          proprietario.provinSedeoperativa +
          ') '
        }}
      </el-descriptions-item>
      <el-descriptions-item
        label="Sede legale"
        label-align="right"
        align="left"
      >
        {{
          proprietario.viaSedelegale +
          ', ' +
          proprietario.numcivSedelegale +
          ' ' +
          proprietario.capSedelegale +
          ' ' +
          proprietario.comunSedelegale +
          ' (' +
          proprietario.provinSedelegale +
          ') '
        }}
      </el-descriptions-item>
      <div v-if="isAdmin">
        <el-descriptions-item
          label="Username GSE"
          label-align="right"
          align="left"
          label-class-name="my-label"
          class-name="my-content"
          :span="2"
        >
          <span @click="copy(proprietario.userGse)">{{
            proprietario.userGse
          }}</span>
        </el-descriptions-item>
        <el-descriptions-item
          label="Password GSE"
          label-align="right"
          align="left"
        >
          <span v-if="proprietario.passGse" @click="copy(proprietario.passGse)">
            ••••••••</span
          >
        </el-descriptions-item>
        <el-descriptions-item
          label="Usernme Terna"
          label-align="right"
          align="left"
          :span="2"
        >
          <span @click="copy(proprietario.userTerna)">
            {{ proprietario.userTerna }}</span
          >
        </el-descriptions-item>
        <el-descriptions-item
          label="Password Terna"
          label-align="right"
          align="left"
        >
          <span
            v-if="proprietario.passTerna"
            @click="copy(proprietario.passTerna)"
          >
            ••••••••</span
          >
        </el-descriptions-item>
        <el-descriptions-item
          label="Username Enel"
          label-align="right"
          align="left"
          :span="2"
        >
          <span @click="copy(proprietario.userEnel)">{{
            proprietario.userEnel
          }}</span>
        </el-descriptions-item>

        <el-descriptions-item
          label="Password Enel"
          label-align="right"
          align="left"
        >
          <span
            v-if="proprietario.passEnel"
            @click="copy(proprietario.passEnel)"
          >
            ••••••••</span
          >
        </el-descriptions-item>

        <el-descriptions-item
          label="Note"
          label-align="right"
          align="left"
          :span="3"
        >
          <span> {{ proprietario.codSicEnel }}</span>
          <!--  <span
            v-if="proprietario.codSicEnel"
            @click="copy(proprietario.codSicEnel)"
          >
            ••••</span
          > -->
        </el-descriptions-item>
      </div>
    </el-descriptions>
    <el-row :gutter="0">
      <el-col :span="4">
        <div class="grid-content" />
        <div class="action-btn">
          <router-link
            class="routerLink"
            :to="{
              path: `/details/proprietario/${proprietarioId}/edit`,
            }"
          >
            <el-button type="info" plain class="choose-btn">
              Modifica
            </el-button>
          </router-link>
        </div>
      </el-col>
      <el-col :span="20"> </el-col
    ></el-row>

    <ReferenteAmministrativoRead
      :refAmm="proprietario.referenteAmministrativo"
      style="margin-top: 25px"
    />

    <contratto-read
      :contratti="proprietario.contratti"
      style="margin-top: 25px"
    />
  </div>
</template>

<script>
import ContrattoRead from './ContrattoRead.vue';
import ReferenteAmministrativoRead from './ReferenteAmministrativoRead.vue';
import { useRoute } from 'vue-router';
import useClipboard from 'vue-clipboard3';

import { ElMessage } from 'element-plus';
import crypto from '@/services/crypto';
import { isLoggedStore } from '@/store/logged';
export default {
  name: 'ProprietarioRead',
  components: {
    ContrattoRead,
    ReferenteAmministrativoRead,
  },
  props: {
    proprietario: { type: Object, default: () => {} },
  },
  setup() {
    const { toClipboard } = useClipboard();
    let route = useRoute();
    let proprietarioId = route.params.id;
    const store = isLoggedStore();
    let isAdmin = crypto.decipher(store.tipo) == 'admin';

    let copy = async (text) => {
      try {
        await toClipboard(text);
        ElMessage('Copiato nella Clipboard');
      } catch (e) {
        console.error(e);
      }
    };
    return { proprietarioId, copy, isAdmin };
  },
};
</script>

<style scoped>
.my-label {
  background: var(--el-color-success-light-9);
}
.my-content {
  background: var(--el-color-danger-light-9);
}
</style>
