<template>
  <h1 class="header-implant">Inverters</h1>
  <div v-if="inverters[selectedInverter] !== undefined">
    <el-row :gutter="0">
      <el-col :span="4">
        <div class="grid-content" />
        <h4 style="text-align: left; margin-top: 0px">Seleziona Inverter</h4>
        <div id="selection" class="choose-section">
          <el-button
            v-for="(inverter, index) in inverters"
            :key="inverter.id"
            type="primary"
            class="choose-btn"
            :plain="!(selectedInverter == index)"
            @click="selectInverter(index)"
          >
            {{ inverter.nome }}
          </el-button>
        </div>
        <div class="action-btn">
          <router-link
            class="routerLink"
            :to="{
              path: `/details/${proprietarioId}/sezione/${sezioneId}/inverters/add`,
            }"
          >
            <el-button type="success" plain class="choose-btn">
              Aggiungi
            </el-button>
          </router-link>
          <router-link
            class="routerLink"
            :to="{
              path: `/details/${proprietarioId}/sezione/${sezioneId}/inverters/${inverters[selectedInverter].id}/edit`,
            }"
          >
            <el-button type="info" plain class="choose-btn">
              Modifica
            </el-button>
          </router-link>
        </div>
      </el-col>
      <el-col :span="20">
        <el-descriptions title="Dettagli Inverter" :column="3" border>
          <el-descriptions-item
            label="Nome inverter"
            label-align="right"
            align="left"
            label-class-name="my-label"
            class-name="my-content"
            :span="2"
          >
            {{ inverters[selectedInverter].nome }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Potenza Nominale"
            label-align="right"
            align="left"
          >
            {{ inverters[selectedInverter].potenzaNominale }}
          </el-descriptions-item>

          <el-descriptions-item label="Marca" label-align="right" align="left">
            {{ inverters[selectedInverter].marca }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Modello"
            label-align="right"
            align="left"
          >
            {{ inverters[selectedInverter].modello }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Matricola"
            label-align="right"
            align="left"
          >
            {{ inverters[selectedInverter].matricola }}
          </el-descriptions-item>

          <el-descriptions-item
            label="Note"
            label-align="right"
            align="left"
            :span="3"
          >
            {{ inverters[selectedInverter].note }}
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>

    <moduli-read
      :inverter-id="inverters[selectedInverter].id"
      :moduli="moduli"
    />
  </div>
  <div v-else>
    <router-link
      class="routerLink"
      :to="{
        path: `/details/${proprietarioId}/sezione/${sezioneId}/inverters/add`,
      }"
    >
      <el-button type="success" plain class="choose-btn">
        Aggiungi Inverter</el-button
      >
    </router-link>
  </div>
</template>

<script>
import ModuliRead from './ModuliRead.vue';
import { useRoute } from 'vue-router';

import { ref } from 'vue';
export default {
  name: 'InvertersRead',
  components: { ModuliRead },
  props: {
    inverters: { type: Array, default: () => [{ moduli: [] }] },
    sezioneId: { type: Number, default: () => 0 },
  },
  setup() {
    let route = useRoute();
    const proprietarioId = route.params.id;
    let selectedInverter = ref(0);
    let selectInverter = (index) => {
      selectedInverter.value = index;
    };

    return {
      selectedInverter,
      selectInverter,
      proprietarioId,
    };
  },
  computed: {
    moduli() {
      return this.inverters[this.selectedInverter].moduli;
    },
  },
};
</script>

<style></style>
