<template>
  <h1>Crea foglio lavoro</h1>

  <el-form :model="worksheet" label-position="top">
    <el-form-item label="Cliente">
      <el-input v-model="worksheet.proprietario" />
    </el-form-item>
    <el-form-item label="Partita IVA / CF">
      <el-input v-model="worksheet.ivaCf" />
    </el-form-item>
    <el-form-item label="Indirizzo">
      <el-input v-model="worksheet.indirizzo" />
    </el-form-item>
    <el-form-item label="Città">
      <el-input v-model="worksheet.comune" />
    </el-form-item>
    <el-form-item label="Provincia">
      <el-input v-model="worksheet.provincia" />
    </el-form-item>
    <el-form-item label="CAP">
      <el-input v-model="worksheet.cap" />
    </el-form-item>

    <el-divider border-style="dashed" />

    <el-form-item label="Referente">
      <el-input v-model="worksheet.referente" />
    </el-form-item>
    <el-form-item label="Mail Referente">
      <el-input v-model="worksheet.referentemail" />
    </el-form-item>
    <el-form-item label="Causale">
      <el-input v-model="worksheet.causale" />
    </el-form-item>

    <el-divider border-style="dashed" />

    <h2>Intervento</h2>
    <el-form-item label="Descrizione Intervento">
      <el-input
        v-model="worksheet.descrizione_intervento"
        :rows="5"
        type="textarea"
        placeholder="Descrivi brevemente l'intervento"
      />
    </el-form-item>
    <div>
      <el-checkbox
        v-model="worksheet.manutenzioneOrdinaria"
        label="Manutenzione Ordinaria"
        size="large"
      />
      <el-checkbox
        v-model="worksheet.manutenzioneStraordinaria"
        label="Manutenzione Straordinaria"
        size="large"
      />
      <el-checkbox v-model="worksheet.garanzia" label="Garanzia" size="large" />
      <el-checkbox
        v-model="worksheet.cantiereOem"
        label="Cantiere O&M"
        size="large"
      />
      <el-checkbox
        v-model="worksheet.necessarioPreventivo"
        label="Necessario Preventivo"
        size="large"
      />
      <el-checkbox
        v-model="worksheet.eccedenzaKm"
        label="Eccedenza Km > 100 Km"
        size="large"
      />
    </div>

    <el-divider border-style="dashed" />
    <h2>Pezzi sostituiti / da sostituire</h2>
    <div
      v-for="(pezzo, counter_pezzi) in worksheet.pezzi"
      v-bind:key="counter_pezzi"
    >
      <el-form-item label="Quantità">
        <el-input-number v-model="pezzo.numero" />
      </el-form-item>
      <el-form-item label="Descrizione">
        <el-input v-model="pezzo.descrizione" />
      </el-form-item>
      <el-form-item label="Tipo">
        <el-input v-model="pezzo.tipo" />
      </el-form-item>
      <el-form-item label="Marca">
        <el-input v-model="pezzo.marca" />
      </el-form-item>
      <div class="mb-2 flex items-center text-sm">
        <el-radio-group v-model="pezzo.sostituito" class="ml-4">
          <el-radio label="1" size="large">Pezzo Sostituito</el-radio>
          <el-radio label="0" size="large">Pezzo da Sostituire</el-radio>
        </el-radio-group>
      </div>
      <el-button
        type="danger"
        :icon="Delete"
        @click="rimuoviPezzi($event, counter_pezzi)"
        circle
      />
    </div>
    <el-button
      style="margin-top: 15px"
      @click="aggiungiPezzi"
      type="success"
      plain
      >Aggiungi pezzi</el-button
    >

    <el-divider border-style="dashed" />
    <h2>Tempi di lavoro</h2>
    <div v-for="(tecnico, counter) in worksheet.personale" v-bind:key="counter">
      <el-form-item label="Nome Tecnico">
        <el-input v-model="tecnico.nome_tecnico" />
      </el-form-item>
      <el-form-item label="Data">
        <el-date-picker
          v-model="tecnico.date"
          type="date"
          placeholder="Pick a date"
          size="default"
        />
      </el-form-item>
      <el-form-item label="Orari di lavoro">
        <el-time-picker
          v-model="tecnico.orario"
          is-range
          range-separator="To"
          start-placeholder="Start time"
          end-placeholder="End time"
        />
      </el-form-item>
      <el-form-item label="Pausa Pranzo">
        <el-switch v-model="tecnico.pranzo" />
      </el-form-item>
      <el-button
        type="danger"
        :icon="Delete"
        @click="rimuoviTecnico($event, counter)"
        circle
      />
    </div>
    <el-button
      style="margin-top: 15px"
      @click="aggiungiTecnico"
      type="success"
      plain
      >Aggiungi orario di lavoro</el-button
    >
    <el-divider border-style="dashed" />

    <el-form-item label="Note">
      <el-input
        v-model="worksheet.noteSuggerimenti"
        :rows="3"
        type="textarea"
        placeholder="Note e suggerimenti"
      />
    </el-form-item>
    <el-divider border-style="dashed" />
    <div class="outer-signature">
      <div class="signature-pad">
        <VPerfectSignature :stroke-options="strokeOptions" ref="signaturePad" />
      </div>
    </div>

    <el-button @click="clearSignature" type="warning" plain
      >Firma di nuovo</el-button
    >
    <el-button @click="saveDraft" type="success" plain>Salva bozza</el-button>
    <el-popconfirm
      confirm-button-text="Yes"
      cancel-button-text="No"
      :icon="InfoFilled"
      icon-color="#626AEF"
      width="300"
      title="Una volta inviato questo foglio lavoro non sarà più modificabile, vuoi procedere?"
      @confirm="send"
    >
      <template #reference>
        <el-button type="success" :disabled="isLoading">Invia Modulo</el-button>
      </template>
    </el-popconfirm>
  </el-form>

  <div></div>
</template>

<script>
import { onMounted, reactive } from 'vue';
import { VPerfectSignature } from 'v-perfect-signature';
import { ref, toRaw } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import detailsApi from '@/services/detailsAPI';
import { Delete } from '@element-plus/icons-vue';
import { isLoggedStore } from '@/store/logged';
import { InfoFilled } from '@element-plus/icons-vue';
import { ElNotification } from 'element-plus';

export default {
  components: { VPerfectSignature },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = isLoggedStore();

    //Inizializzo variabili di utility e schema
    const signaturePad = ref();
    const strokeOptions = {
      size: 12,
      thinning: 0.75,
      smoothing: 0.5,
      streamline: 0.5,
    };
    const getStartTime = () => {
      let startTime = new Date();
      startTime.setHours(8);
      startTime.setMinutes(0);
      startTime.setSeconds(0);
      return startTime;
    };

    const getEndTime = () => {
      let endTime = new Date();
      endTime.setHours(17);
      endTime.setMinutes(0);
      endTime.setSeconds(0);
      return endTime;
    };

    /* Aggiungere causale e referente */
    const user = store.user ? store.user : 'operatore';
    const id = route.params.id !== 'manual' ? route.params.id : null;
    const worksheet = reactive({
      fkUser: user,
      fkProprietario: id,
      fatturato: 0,
      proprietario: '',
      indirizzo: '',
      comune: '',
      provincia: '',
      cap: '',
      ivaCf: '',
      referente: '',
      referentemail: '',

      descrizione_intervento: '',

      manutenzioneOrdinaria: false,
      manutenzioneStraordinaria: false,
      necessarioPreventivo: false,
      eccedenzaKm: false,
      cantiereOem: false,
      garanzia: false,

      pezzi_sostituiti: false,
      pezzi_da_ordinare: false,
      inviato: 0,

      pezzi: [],
      personale: [
        {
          nome_tecnico: store.nome,
          date: new Date(),
          orario: [getStartTime(), getEndTime()],
          pranzo: false,
        },
      ],

      noteSuggerimenti: '',
      causale: '',
      date: new Date(),
      tecnico: '',
    });

    const aggiungiTecnico = (e) => {
      worksheet.personale.push({
        nome_tecnico: store.nome,
        date: new Date(),
        orario: [getStartTime(), getEndTime()],
        pranzo: false,
      });
      e.preventDefault();
    };


    const convertBooleanFields = (inputObj) => {
  // Helper function to perform the conversion
  function convert(obj) {
    let newObj = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'boolean') {
          newObj[key] = obj[key] ? 1 : 0;
        } else if (typeof obj[key] === 'object' && obj[key] !== null && !(obj[key] instanceof Date)) {
          newObj[key] = convert(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
    return newObj;
  }

  // Return the converted object
  return convert(inputObj);
}

    const rimuoviTecnico = (e, counter) => {
      worksheet.personale.splice(counter, 1);
      e.preventDefault();
    };
    const rimuoviPezzi = (e, counter) => {
      worksheet.pezzi.splice(counter, 1);
      e.preventDefault();
    };
    const aggiungiPezzi = (e) => {
      worksheet.pezzi.push({
        numero: 1,
        descrizione: '',
        tipo: '',
        marca: '',
        sostituito: '1',
      });
      e.preventDefault();
    };



    const clearSignature = (e) => {
      e.preventDefault();
      signaturePad.value.clear();
    };
    const print = (e) => {
      e.preventDefault();
      console.log(signaturePad.value.toDataURL());
    };

    let isLoading = reactive(false);

    const send = (e) => {
      if (
        worksheet.manutenzioneOrdinaria != 1 &&
        worksheet.manutenzioneStraordinaria != 1 &&
        worksheet.cantiereOem != 1 &&
        worksheet.garanzia != 1
      ) {
        ElNotification({
          title: 'Attenzione',
          message: 'è necessario indicare il tipo di manutenzione',
          type: 'error',
        });
        return;
      }
      worksheet.inviato = 1;
      isLoading = true;
      e.preventDefault();
      let orari = [];
      let pezzi = [];

      for (let orario of worksheet.personale) {
        console.log(orario);
        orari.push({
          personale: orario.nome_tecnico,
          data: orario.date,
          inizio: orario.orario[0],
          fine: orario.orario[1],
          pranzo: orario.pranzo,
        });
      }

      for (let pezzo of worksheet.pezzi) {
        console.log(pezzo);
        pezzi.push({
          numero: pezzo.numero,
          descrizione: pezzo.descrizione,
          tipo: pezzo.tipo,
          marca: pezzo.marca,
          sostituire: pezzo.sostituito,
        });
      }

      worksheet.orari = orari;
      worksheet.pezzi = pezzi;

      worksheet.signature = signaturePad.value.toDataURL();
      console.log(toRaw(worksheet));
      let worksheet2 = convertBooleanFields(worksheet)
      detailsApi.addWorksheet(toRaw(worksheet2)).then((e) => {
        let worksheetId = e.data.id;
        detailsApi.sendMail(worksheetId, worksheet.referentemail).then((e) => {
          isLoading = false;
          console.log(e);
          router.push('/tecnician-home');
        });
      });
    };

    const saveDraft = (e) => {
      worksheet.inviato = 0;
      isLoading = true;
      e.preventDefault();
      let orari = [];
      let pezzi = [];

      for (let orario of worksheet.personale) {
        orari.push({
          personale: orario.nome_tecnico,
          data: orario.date,
          inizio: orario.orario[0],
          fine: orario.orario[1],
          pranzo: orario.pranzo,
        });
      }

      for (let pezzo of worksheet.pezzi) {
        pezzi.push({
          numero: pezzo.numero,
          descrizione: pezzo.descrizione,
          tipo: pezzo.tipo,
          marca: pezzo.marca,
          sostituire: pezzo.sostituito,
        });
      }

      worksheet.orari = orari;
      worksheet.pezzi = pezzi;
      
      worksheet.signature = signaturePad.value.toDataURL();

      let worksheet2 = convertBooleanFields(worksheet)
      detailsApi.addWorksheet(toRaw(worksheet2)).then(() => {
        isLoading = false;
        router.push('/tecnician-home');
      });
    };

    let loadWorksheet = async (id) => {
      
      if(id == "manual") return;
      
      let { proprietario, referenti } = (await detailsApi.getWorksheetData(id))
        .data;
      let referente = referenti.pop();
      if (proprietario) {
        worksheet.proprietario =
          proprietario.ragione +
          '' +
          proprietario.nome +
          ' ' +
          proprietario.cognome;

        worksheet.ivaCf =
          proprietario.piva.length >= proprietario.cf.length
            ? proprietario.piva
            : proprietario.cf;

        worksheet.indirizzo =
          proprietario.viaSedelegale + ', ' + proprietario.numcivSedelegale;
        worksheet.comune = proprietario.comunSedelegale;
        worksheet.provincia = proprietario.provinSedelegale;
        worksheet.cap = proprietario.capSedelegale;
      }
      if (referente) {
        worksheet.referente = referente.nome + ' ' + referente.cognome;
        worksheet.referentemail = referente.mail;
      }
    };

    onMounted(() => {
      loadWorksheet(route.params.id);
    });

    return {
      worksheet,
      aggiungiTecnico,
      rimuoviTecnico,
      aggiungiPezzi,
      rimuoviPezzi,
      strokeOptions,
      signaturePad,
      print,
      clearSignature,
      send,
      Delete,
      isLoading,
      InfoFilled,
      saveDraft,
    };
  },
};
</script>

<style>
.outer-signature {
  display: flex;
  align-items: center;
  justify-content: center;
}

.signature-pad {
  width: 90%;
  height: 400px;
  border: 3px solid black;
}
</style>
