<template>
  <h1>Modifica {{ contratto.nome }}</h1>

  <el-form :model="contratto" label-width="240px">
    <el-form-item label="Nome Contratto">
      <el-input v-model="contratto.nome" />
    </el-form-item>
    <el-form-item label="Cambia PDF">
      <input type="file" @change="onFileSelected" />
    </el-form-item>
    <el-form-item label="Manutenzione Ordinaria">
      <el-input-number v-model="contratto.manutenzioneOrdinaria" />
    </el-form-item>
    <el-form-item label="Cliente senza contratto">
      <el-switch
        v-model="contratto.clientesenzacontratto"
        :active-value="1"
        :inactive-value="0"
      />
    </el-form-item>
    <el-form-item label="Assistenza Amministrativa">
      <el-switch
        v-model="contratto.assistenzaAmm"
        :active-value="1"
        :inactive-value="0"
      />
    </el-form-item>
    <el-form-item label="Telecontrollo">
      <el-switch
        v-model="contratto.telecontrollo"
        :active-value="1"
        :inactive-value="0"
      />
    </el-form-item>
    <el-form-item label="Manutenzione Straordinaria">
      <el-switch
        v-model="contratto.straordinaria"
        :active-value="1"
        :inactive-value="0"
      />
    </el-form-item>
    <el-form-item label="Lavaggio Moduli">
      <el-switch
        v-model="contratto.lavaggioModuli"
        :active-value="1"
        :inactive-value="0"
      />
    </el-form-item>
    <el-form-item label="Dichiarazioni di Consumo">
      <el-switch
        v-model="contratto.dichiarazionidiconsumo"
        :active-value="1"
        :inactive-value="0"
      />
    </el-form-item>
    <el-form-item label="Taglio Erba">
      <el-switch
        v-model="contratto.taglioerba"
        :active-value="1"
        :inactive-value="0"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> Modifica </el-button>
      <el-button @click="annulla"> Annulla </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, reactive, toRaw } from 'vue';
import detailsAPI from '../../services/detailsAPI';

export default {
  setup() {
    //Inizializzo variabili e schema
    const router = useRouter();
    const route = useRoute();
    const proprietarioId = route.params.id;
    const contrattoId = route.params.contrattoId;
    const convertBooleanFields = (inputObj) => {
  // Helper function to perform the conversion
  function convert(obj) {
    let newObj = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'boolean') {
          newObj[key] = obj[key] ? 1 : 0;
        } else if (typeof obj[key] === 'object' && obj[key] !== null && !(obj[key] instanceof Date)) {
          newObj[key] = convert(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
    return newObj;
  }

  // Return the converted object
  return convert(inputObj);
}


    let contratto = reactive({
      id: 0,
      nome: '',
      pdf: '',
      manutenzioneOrdinaria: 0,
      clientesenzacontratto: 0,
      assistenzaAmm: 0,
      telecontrollo: 0,
      straordinaria: 0,
      lavaggioModuli: 0,
      dichiarazionidiconsumo: 0,
      taglioerba: 0,
      fkProprietario: 0,
    });

    //Inizializzo bottoni
    const editContract = async (id, contratto) => {
      let response = {};
      try {
        let contratto2 = convertBooleanFields(contratto)
        response = await detailsAPI.patchContract(id, contratto2);
        return response;
      } catch {
        console.log('error');
      }
    };

    const annulla = () => {
      router.push(`/details/${proprietarioId}`);
    };

    //Riempio una volta xaricata la pagina
    const getContract = async (contrattoId) => {
      let response = {};
      try {
        response = await detailsAPI.getContract(contrattoId);
        return response.data;
      } catch {
        console.log('error');
      }
    };

    onMounted(() => {
      getContract(contrattoId).then((contractDetail) => {
        if (contractDetail) {
          (contratto.id = contractDetail.id),
            (contratto.nome = contractDetail.nome),
            (contratto.pdf = contractDetail.pdf),
            (contratto.manutenzioneOrdinaria =
              contractDetail.manutenzioneOrdinaria),
            (contratto.clientesenzacontratto =
              contractDetail.clientesenzacontratto),
            (contratto.assistenzaAmm = contractDetail.assistenzaAmm),
            (contratto.telecontrollo = contractDetail.telecontrollo),
            (contratto.straordinaria = contractDetail.straordinaria),
            (contratto.lavaggioModuli = contractDetail.lavaggioModuli),
            (contratto.dichiarazionidiconsumo =
              contractDetail.dichiarazionidiconsumo),
            (contratto.taglioerba = contractDetail.taglioerba),
            (contratto.fkProprietario = proprietarioId);
        }
      });
    });

    let selectedFile = null;

    const onFileSelected = (e) => {
      if (e.target.files === null) return;
      selectedFile = e.target.files[0];
    };

    const onSubmit = () => {
      if (selectedFile) {
        let fd = new FormData();
        fd.append('pdf', selectedFile, selectedFile.name);
        try {
          detailsAPI.upload(fd).then((resp) => {
            if (resp.status == 201) {
              contratto.pdf = resp.data;

              editContract(contrattoId, toRaw(contratto)).then((response) => {
                console.log(response.data);
                if (response.status === 200) {
                  console.log('Contratto modificato!');
                  router.push(`/details/${proprietarioId}`);
                } else {
                  console.log('errore inatteso');
                }
              });
              console.log(contratto.pdf);
            }
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        editContract(contrattoId, toRaw(contratto)).then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            console.log('Contratto modificato!');
            router.push(`/details/${proprietarioId}`);
          } else {
            console.log('errore inatteso');
          }
        });
      }
    };

    return {
      proprietarioId,
      contratto,
      onSubmit,
      annulla,
      onFileSelected,
    };
  },
};
</script>
