<template>
  <h1>Filtra i fogli lavoro</h1>
  <el-form
    :model="filters"
    label-width="200px"
    label="top"
    class="demo-form-inline"
  >
    <el-form-item label="Seleziona una Data">
      <el-date-picker
        v-model="filters.date"
        type="daterange"
        range-separator="Fino a "
        start-placeholder="Data inizio"
        end-placeholder="Data fine"
        size="default"
      />
    </el-form-item>

    <el-form-item label="Cliente">
      <el-select
        v-model="filters.fkProprietario"
        filterable
        placeholder="Seleziona"
      >
        <el-option
          v-for="item in customerOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Operatore">
      <el-select v-model="filters.operatore" filterable placeholder="Seleziona">
        <el-option
          v-for="item in operatorOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="handleClick">Query</el-button>
    </el-form-item>
  </el-form>

  <div class="mt-4">
    <el-table :data="filteredTable" max-height="500" style="width: 100%">
      <el-table-column prop="proprietario" label="Cliente" />
      <el-table-column prop="nome" label="Tecnico" />
      <el-table-column label="Data">
        <template #default="datarow">
          {{ new Date(datarow.row.data).toLocaleDateString('IT-it') }}
        </template>
      </el-table-column>
      <el-table-column label="Manutenzione">
        <template #default="id">
          <el-tag v-if="id.row.manutenzione_straordinaria == 1"
            >Manutenzione Straordinaria</el-tag
          >
          <el-tag v-if="id.row.manutenzione_ordinaria == 1"
            >Manutenzione Ordinaria</el-tag
          >
          <el-tag v-if="id.row.cantiere_oem == 1">Cantiere O&M</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Fogli lavoro">
        <template #default="test">
          <el-button size="small" @click="seeWorksheets(test.row.id)">
            Visualizza Foglio di Lavoro
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Fattura">
        <template #default="scope">
          <el-tag
            type="success"
            v-if="
              scope.row.fatturato == 1 &&
              scope.row.manutenzione_straordinaria == 1
            "
            >Fattura n° {{ scope.row.numero_fattura }}</el-tag
          >
          <el-tag
            type="info"
            v-if="
              scope.row.fatturato == 1 &&
              scope.row.manutenzione_straordinaria == 0
            "
            >Archiviato</el-tag
          >
          <el-tag v-if="scope.row.fatturato == 0">Da Fatturare</el-tag>
        </template>
      </el-table-column></el-table
    >
  </div>
</template>

<script>
import { ref } from 'vue';
import searchAPI from '@/services/searchAPI';
import detailsAPI from '@/services/detailsAPI';
import { dayjs } from 'element-plus';

export default {
  components: [],
  setup() {
    let customerOptions = ref([{ value: -1, label: 'Chiunque' }]);
    let operatorOptions = ref([{ value: 'all', label: 'Chiunque' }]);

    let filters = ref({
      date: [dayjs(new Date()).subtract(7, 'day'), dayjs(new Date())],
      fkProprietario: -1,
      operatore: 'all',
    });

    let filteredTable = ref([]);

    const load = async () => {
      let customers = (await searchAPI.getCustomerList()).data;
      for (let customer of customers) {
        customerOptions.value.push({
          value: customer.id,
          label: customer.ragione + customer.nome + ' ' + customer.cognome,
        });
      }

      let operators = (await searchAPI.getOperatorList()).data;

      for (let operator of operators) {
        operatorOptions.value.push({
          value: operator.username,
          label: operator.nome,
        });
      }
    };

    let handleClick = async () => {
      let options = {
        customerId: filters.value.fkProprietario,
        operatorId: filters.value.operatore,
        startDate: new Date(filters.value.date[0]),
        endDate: new Date(filters.value.date[1]),
      };
      let data = (await searchAPI.filterWorksheet(options)).data;
      console.log(data);
      filteredTable.value = data;
    };

    let seeWorksheets = async (id) => {
      await detailsAPI.printWorksheet(id);
    };

    load();
    return {
      filteredTable,
      handleClick,
      customerOptions,
      operatorOptions,
      filters,
      seeWorksheets,
    };
  },
};
</script>

<style></style>
