var CryptoJS = require('crypto-js');

export default {
  cipher(text) {
    return CryptoJS.AES.encrypt(text, 'yodalahihiyodalauhuh').toString();
  },
  decipher(text) {
    let bytes = CryptoJS.AES.decrypt(text, 'yodalahihiyodalauhuh');
    return bytes.toString(CryptoJS.enc.Utf8);
  },
};
