<template>
  <h2>Non hai i permessi di visualizzare questa pagina.</h2>
  <h4>Se pensi che dovresti contatta l'amministatore a m.russo@enami.it</h4>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>
