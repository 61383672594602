<template>
  <h1>Accetta fogli lavoro</h1>
  <el-table :data="worksheets" max-height="500" style="width: 100%">
    <el-table-column prop="proprietario" label="Cliente" />
    <el-table-column prop="tecnico" label="Tecnico" />
    <el-table-column prop="data" label="Data" />
    <el-table-column label="Label">
      <template #default="scope">
        <el-tag v-if="scope.row.ordinario == 1">Manutenzione Ordinaria</el-tag
        ><br />
        <el-tag v-if="scope.row.straordinario == 1"
          >Manutenzione Straordinaria</el-tag
        ><br />
        <el-tag v-if="scope.row.cantiereOem == 1">Cantiere Oem</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="Fogli Lavoro">
      <template #default="test">
        <el-button size="small" @click="seeWorksheets(test.row.id)">
          Apri
        </el-button>
      </template>
    </el-table-column>
    <el-table-column label="Preventivatore">
      <template #default="id">
        <el-button size="small" @click="openPriceCalculator(id.row.id)"
          >Preventivo</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { isLoggedStore } from '@/store/logged';
import { ref } from 'vue';
import detailsAPI from '../../services/detailsAPI';
import { useRouter } from 'vue-router';

export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const store = isLoggedStore();
    const nome_segretaria = store.nome;
    const router = useRouter();
    let worksheets = ref([]);

    const loadToInvoiceWorksheet = async () => {
      let notInvoicedWorksheet = (await detailsAPI.loadToInvoceWorksheets())
        .data;
      console.log(notInvoicedWorksheet);
      for (let worksheet of notInvoicedWorksheet) {
        worksheets.value.push({
          id: worksheet.id,
          data: new Date(worksheet.date).toLocaleDateString('IT-it'),
          tecnico: worksheet.fkUser.nome,
          proprietario: worksheet.proprietario,
          ordinario: worksheet.manutenzioneOrdinaria,
          straordinario: worksheet.manutenzioneStraordinaria,
          cantiere: worksheet.cantiereOem,
        });
      }
    };

    const seeWorksheets = async (id) => {
      await detailsAPI.printWorksheet(id);
    };

    const fatturato = async (index, id) => {
      const resp = await detailsAPI.invoiceWorksheet(id);
      if (resp.status === 200) {
        worksheets.value.splice(index, 1);
      } else {
        console.log('error');
      }
    };

    const openPriceCalculator = (id) => {
      router.push('/price-calculator/' + id);
      console.log(id);
    };

    loadToInvoiceWorksheet();
    return {
      nome_segretaria,
      seeWorksheets,
      worksheets,
      fatturato,
      openPriceCalculator,
    };
  },
};
</script>
