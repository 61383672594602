<template>
  <div
    style="
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-item: center;
    "
  >
    <div
      style="
        width: 400px;
        height: 210px;
        border: 1px solid #409eff;
        border-radius: 20px;
        padding: 20px;
      "
    >
      <h1>Accedi</h1>
      <el-form :model="loginData" label-width="100px">
        <el-form-item label="Nome utente">
          <el-input v-model="loginData.username" />
        </el-form-item>
        <el-form-item label="Password">
          <el-input
            v-model="loginData.password"
            type="password"
            placeholder="Inserire password"
            show-password
          />
        </el-form-item>

        <el-form-item style="text-align: center; margin-left: 0px">
          <el-button type="primary" @click="onSubmit"> Accedi </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { reactive, toRaw } from 'vue';
import searchAPI from './../services/searchAPI';
import { isLoggedStore } from '@/store/logged';
import crypto from './../services/crypto';
export default {
  setup() {
    const router = useRouter();
    const store = isLoggedStore();
    const loginData = reactive({
      username: '',
      password: '',
    });

    //Inizializzo i pulsanti
    const onSubmit = () => {
      login(toRaw(loginData)).then((response) => {
        if (response.status === 201) {
          store.isLogged = true;
          store.nome = response.data.nome;
          store.tipo = crypto.cipher(response.data.tipo);
          store.user = loginData.username;
          store.viewMenu = true;
          router.push('/');
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const annulla = () => {
      router.push(`/details/`);
    };

    // Funzioni per aggiungere nuovo
    const login = async (loginData) => {
      let response = {};
      try {
        response = await searchAPI.login(loginData);
        return response;
      } catch {
        console.log('error');
      }
    };

    return {
      loginData,
      onSubmit,
      annulla,
    };
  },
};
</script>
