<template>
  <h1>Modifica {{ inverter.nome }}</h1>

  <el-form
    :model="inverter"
    label-width="240px"
  >
    <el-form-item label="Nome Inverter">
      <el-input v-model="inverter.nome" />
    </el-form-item>
    <el-form-item label="Potenza nominale">
      <el-input-number v-model="inverter.potenzaNominale" />
    </el-form-item>
    <el-form-item label="Marca">
      <el-input v-model="inverter.marca" />
    </el-form-item>
    <el-form-item label="Modello">
      <el-input v-model="inverter.modello" />
    </el-form-item>

    <el-form-item label="Matricola">
      <el-input v-model="inverter.matricola" />
    </el-form-item>
    <el-form-item label="Note">
      <el-input
        v-model="inverter.note"
        type="textarea"
      />
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
      >
        Modifica
      </el-button>
      <el-button @click="annulla">
        Annulla
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, reactive, toRaw } from 'vue';
import detailsAPI from '../../services/detailsAPI';

export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const route = useRoute();
    const proprietarioId = route.params.id;
    const inverterId = route.params.inverterId;

    const inverter = reactive({
      id: 0,
      nome: '',
      marca: '',
      potenzaNominale: 0,
      note: '',
      modello: '',
      matricola: '',
      fkSezione: 0,
    });

    //Inizializzo i pulsanti
    const onSubmit = () => {
      editInverter(inverterId, toRaw(inverter)).then((response) => {
        if (response.status === 200) {
          router.push(`/details/${proprietarioId}`);
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const annulla = () => {
      router.push(`/details/${proprietarioId}`);
    };

    //Riempio una volta caricata la pagina
    const getInverter = async (id) => {
      let response = {};
      try {
        response = await detailsAPI.getInverter(id);
        return response.data;
      } catch {
        console.log('error');
      }
    };

    onMounted(() => {
      getInverter(inverterId).then((inverterDetail) => {
        if (inverterDetail) {
          Object.assign(inverter, inverterDetail);
        }
      });
    });

    // Funzioni per modificare
    const editInverter = async (id, inverter) => {
      let response = {};
      try {
        response = await detailsAPI.patchInverter(id, inverter);
        return response;
      } catch {
        console.log('error');
      }
    };

    return {
      proprietarioId,
      inverter,
      onSubmit,
      annulla,
    };
  },
};
</script>
