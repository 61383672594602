<template>
  <h1>Nuovo Modulo</h1>

  <el-form :model="modulo" label-width="240px">
    <el-form-item label="Marca">
      <el-autocomplete
        v-model="modulo.marca"
        value-key="marca"
        :fetch-suggestions="querySearchMarca"
        :trigger-on-focus="false"
        clearable
        placeholder="Inserisci Marca"
        class="autocomplete"
      />
    </el-form-item>
    <el-form-item label="Modello">
      <el-autocomplete
        v-model="modulo.modello"
        value-key="modello"
        :fetch-suggestions="querySearchModello"
        :trigger-on-focus="false"
        clearable
        placeholder="Inserisci Modello"
        class="autocomplete"
      />
    </el-form-item>
    <el-form-item label="Numero">
      <el-input-number v-model="modulo.numero" />
    </el-form-item>
    <el-form-item label="Potenza">
      <el-input-number v-model="modulo.potenza" />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit"> Aggiungi </el-button>
      <el-button @click="annulla"> Annulla </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { reactive, toRaw, onMounted } from 'vue';
import detailsAPI from '../../services/detailsAPI';
import searchAPI from '../../services/searchAPI';
import { ElMessage } from 'element-plus';
export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const route = useRoute();
    const inverterId = route.params.inverterId;
    const proprietarioId = route.params.id;

    const modulo = reactive({
      nome: '',
      marca: '',
      potenza: 0,
      numero: 0,
      modello: '',
      matricola: '',
      fkInverter: inverterId,
    });

    //Inizializzo i pulsanti
    const onSubmit = () => {
      if (modulo.marca == '' || modulo.modello == '') {
        ElMessage({
          message: 'Inserire sia marca che modello',
          type: 'warning',
        });
        return;
      }
      addNewModulo(toRaw(modulo)).then((response) => {
        console.log(response);
        if (response.status === 201) {
          console.log('Modulo aggiunto!');
          router.push(`/details/${proprietarioId}`);
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const annulla = () => {
      router.push(`/details/${proprietarioId}`);
    };

    // Funzioni per aggiungere nuovo
    const addNewModulo = async (modulo) => {
      console.log(modulo);
      let response = {};
      try {
        response = await detailsAPI.addModulo(modulo);
        console.log(response);
        return response;
      } catch {
        console.log('error');
      }
    };

    //Autocomplete funzionalities
    let marca = [];
    let modello = [];

    const querySearchMarca = (queryString, cb) => {
      const results = queryString
        ? marca.filter(createFilterMarca(queryString))
        : marca;
      // call callback function to return suggestions
      cb(results);
    };

    const createFilterMarca = (queryString) => {
      return (item) => {
        return item.marca.toLowerCase().indexOf(queryString.toLowerCase()) == 0;
      };
    };

    const querySearchModello = (queryString, cb) => {
      const results = queryString
        ? modello.filter(createFilterModello(queryString))
        : modello;
      // call callback function to return suggestions
      cb(results);
    };

    const createFilterModello = (queryString) => {
      return (item) => {
        return (
          item.modello.toLowerCase().indexOf(queryString.toLowerCase()) == 0
        );
      };
    };

    onMounted(() => {
      searchAPI.getAutocompleteModule().then((result) => {
        marca = result.data.marca;
        modello = result.data.modello;
      });
    });
    return {
      proprietarioId,
      querySearchMarca,
      querySearchModello,
      modulo,
      onSubmit,
      annulla,
    };
  },
};
</script>
