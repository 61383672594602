<template>
  <h1>Filtra i contratti</h1>
  <el-form
    :model="filters"
    label-width="200px"
    label="top"
    class="demo-form-inline"
  >
    <el-col :span="11">
      <el-form-item label="Assistenza Amministrativa">
        <el-select v-model="filters.assistenzaAmm">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :span="11">
      <el-form-item label="Telecontrollo">
        <el-select v-model="filters.telecontrollo">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-col>
    <el-form-item label="Manutenzione Ordinaria">
      <el-select v-model="filters.manutenzioneOrdinaria">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Manutenzione Straordinaria">
      <el-select v-model="filters.straordinaria">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Lavaggio Moduli">
      <el-select v-model="filters.lavaggioModuli">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Cliente Senza Contratto">
      <el-select v-model="filters.clientesenzacontratto">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Dichiarazioni di Consumo">
      <el-select v-model="filters.dichiarazionidiconsumo">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item label="Taglio Erba">
      <el-select v-model="filters.taglioerba">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="handleClick">Query</el-button>
    </el-form-item>
  </el-form>

  <div class="mt-4">
    <el-table :data="filteredTable" height="500" style="width: 100%">
      <el-table-column prop="cliente" label="Denominazione" />
      <el-table-column prop="contratto" label="contratto" />
      <el-table-column label="Badge">
        <template #default="scope">
          <router-link
            :to="{ path: `/details/${scope.row.id}` }"
            class="routerLink"
          >
            <el-button size="small"> Visualizza </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ref } from 'vue';
import searchAPI from '@/services/searchAPI';

export default {
  components: [],
  setup() {
    let options = [
      {
        value: 'both',
        label: 'Entrambi',
      },
      {
        value: 1,
        label: 'Si',
      },
      {
        value: 0,
        label: 'No',
      },
    ];
    let filters = ref({
      assistenzaAmm: 'both',
      telecontrollo: 'both',
      manutenzioneOrdinaria: 'both',
      straordinaria: 'both',
      lavaggioModuli: 'both',
      clientesenzacontratto: 'both',
      dichiarazionidiconsumo: 'both',
      taglioerba: 'both',
    });

    let filteredTable = ref([]);
    let handleClick = async () => {
      filteredTable.value = [];
      let contracts = await searchAPI.filterContract(filters.value);
      console.log(contracts.data);
      for (let contract of contracts.data) {
        if (contract.fkProprietario) {
          filteredTable.value.push({
            id: contract.fkProprietario.id,
            cliente:
              contract.fkProprietario.ragione +
              contract.fkProprietario.nome +
              ' ' +
              contract.fkProprietario.cognome,
            contratto: contract.nome,
          });
        }
      }
    };
    return {
      filteredTable,
      handleClick,
      options,
      filters,
    };
  },
};
</script>

<style></style>
