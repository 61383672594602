<template>
  <img src="../assets/logo.png" class="logo" />
  <h2>Contatori</h2>
  <div class="mt-4">
    <el-table :data="tableContatoriData" height="300" style="width: 100%">
      <el-table-column prop="marca" label="Marca" />
      <el-table-column prop="modello" label="Modello" />

      <el-table-column prop="scadenza" label="Data di scadenza" />
      <el-table-column prop="nomeCentrale" label="Centrale" />
      <el-table-column prop="proprietario" label="Proprietario" />
      <el-table-column label="Badge">
        <template #default="scope">
          <el-tag v-if="scope.row.badge === 1" class="ml-2" type="danger"
            >Urgente!</el-tag
          >
          <el-tag v-if="scope.row.badge === 2" class="ml-2" type="warning"
            >A breve!</el-tag
          >
          <router-link
            :to="{ path: `/details/${scope.row.id}` }"
            class="routerLink"
          >
            <el-button size="small"> Visualizza </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <h2>Interfacce</h2>
  <div class="mt-4">
    <el-table :data="tableInterfaccieData" height="300" style="width: 100%">
      <el-table-column prop="marca" label="Marca" />
      <el-table-column prop="modello" label="Modello" />

      <el-table-column prop="scadenza" label="Data di scadenza" />
      <el-table-column prop="nomeCentrale" label="Centrale" />
      <el-table-column prop="proprietario" label="Proprietario" />
      <el-table-column label="Badge">
        <template #default="scope">
          <el-tag v-if="scope.row.badge === 1" class="ml-2" type="danger"
            >Urgente!</el-tag
          >
          <el-tag v-if="scope.row.badge === 2" class="ml-2" type="warning"
            >A breve!</el-tag
          >
          <router-link
            :to="{ path: `/details/${scope.row.id}` }"
            class="routerLink"
          >
            <el-button size="small"> Visualizza </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { Search } from '@element-plus/icons-vue';
import { ref } from 'vue';
import searchAPI from '../services/searchAPI';

export default {
  components: [Search],
  setup() {
    const input = ref('');
    const tableContatoriData = ref([]);
    const tableInterfaccieData = ref([]);

    const loadCustomers = async () => {
      try {
        const response = await searchAPI.getScadenze();
        response.data.scadenzeGraviContatore.forEach((row) => {
          let tableRow = {
            id: row.id_proprietario,
            marca: row.marca_cont,
            modello: row.modello_cont,
            scadenza: new Date(
              row.scadenza_certificato_contatore
            ).toLocaleDateString('IT-it'),
            nomeCentrale: row.nome_centrale,
            proprietario: row.ragione + row.nome + ' ' + row.cognome,
            badge: 1,
          };
          tableContatoriData.value.push(tableRow);
        });
        response.data.scadenzeProssimeContatore.forEach((row) => {
          let tableRow = {
            id: row.id_proprietario,
            marca: row.marca_cont,
            modello: row.modello_cont,
            scadenza: new Date(
              row.scadenza_certificato_contatore
            ).toLocaleDateString('IT-it'),
            nomeCentrale: row.nome_centrale,
            proprietario: row.ragione + row.nome + ' ' + row.cognome,
            badge: 2,
          };
          tableContatoriData.value.push(tableRow);
        });

        response.data.scadenzeGraviInterfaccia.forEach((row) => {
          let tableRow = {
            id: row.id_proprietario,
            marca: row.marca_inter,
            modello: row.mod_inter,
            scadenza: new Date(
              row.scadenza_certificato_interfaccia
            ).toLocaleDateString('IT-it'),
            nomeCentrale: row.nome_centrale,
            proprietario: row.ragione + row.nome + ' ' + row.cognome,
            badge: 1,
          };
          tableInterfaccieData.value.push(tableRow);
        });

        response.data.scadenzeProssimeInterfaccia.forEach((row) => {
          let tableRow = {
            id: row.id_proprietario,
            marca: row.marca_inter,
            modello: row.mod_inter,
            scadenza: new Date(
              row.scadenza_certificato_interfaccia
            ).toLocaleDateString('IT-it'),
            nomeCentrale: row.nome_centrale,
            proprietario: row.ragione + row.nome + ' ' + row.cognome,
            badge: 2,
          };
          tableInterfaccieData.value.push(tableRow);
        });
      } catch {
        console.log('error');
      }
    };
    loadCustomers();

    return {
      input,
      tableContatoriData,
      tableInterfaccieData,
    };
  },
};
</script>

<style>
.logo {
  width: 100px;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
