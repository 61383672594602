<template>
  <div v-if="contratti[selectedContract] !== undefined">
    <h1 class="header-implant">Contratti</h1>

    <el-row :gutter="0">
      <el-col :span="4">
        <div class="grid-content" />
        <h4 style="text-align: left; margin-top: 0px">Seleziona contratto</h4>
        <div id="selection" class="choose-section">
          <el-button
            v-for="(contratto, index) in contratti"
            :key="contratto.id"
            type="primary"
            class="choose-btn"
            :plain="!(selectedContract == index)"
            @click="selectContract(index)"
          >
            {{ contratto.nome }}
          </el-button>
        </div>
        <div class="action-btn">
          <router-link
            class="routerLink"
            :to="{ path: `/details/${proprietarioId}/contracts/add` }"
          >
            <el-button type="success" plain class="choose-btn">
              Aggiungi
            </el-button>
          </router-link>
          <router-link
            class="routerLink"
            :to="{
              path: `/details/${proprietarioId}/contracts/${contratti[selectedContract].id}/edit`,
            }"
          >
            <el-button type="info" plain class="choose-btn">
              Modifica
            </el-button>
          </router-link>
        </div>
      </el-col>
      <el-col :span="20">
        <el-descriptions title="Dettagli contratto" :column="3" border>
          <el-descriptions-item
            label="Nome contratto"
            label-align="right"
            align="left"
            label-class-name="my-label"
            class-name="my-content"
          >
            {{ contratti[selectedContract].nome }}
          </el-descriptions-item>

          <el-descriptions-item
            label="Manutenzione Ordinaria"
            label-align="right"
            align="left"
          >
            {{ contratti[selectedContract].manutenzioneOrdinaria }}
          </el-descriptions-item>

          <el-descriptions-item label="PDF" label-align="right" align="left">
            <el-button round @click="openFile(contratti[selectedContract].pdf)"
              >Apri</el-button
            >
          </el-descriptions-item>

          <el-descriptions-item
            label="Servizi"
            label-align="right"
            align="left"
            :span="3"
          >
            <el-tag
              v-if="contratti[selectedContract].clientesenzacontratto"
              type="info"
              class="mx-1"
            >
              Cliente Senza Contratto
            </el-tag>
            <el-tag
              v-if="contratti[selectedContract].assistenzaAmm"
              type="success"
              class="mx-1"
            >
              Assistenza amministrativa
            </el-tag>
            <el-tag
              v-if="contratti[selectedContract].telecontrollo"
              type="success"
              class="mx-1"
            >
              Telecontrollo
            </el-tag>
            <el-tag
              v-if="contratti[selectedContract].straordinaria"
              type="success"
              class="mx-1"
            >
              Manutenzione Straordinaria
            </el-tag>
            <el-tag
              v-if="contratti[selectedContract].lavaggioModuli"
              type="success"
              class="mx-1"
            >
              Lavaggio Moduli
            </el-tag>
            <el-tag
              v-if="contratti[selectedContract].dichiarazionidiconsumo"
              type="success"
              class="mx-1"
            >
              Dichiarazioni di consumo
            </el-tag>
            <el-tag
              v-if="contratti[selectedContract].taglioerba"
              type="success"
              class="mx-1"
            >
              Taglio Erba
            </el-tag>
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>

    <CentraliRead
      :centrali="centrali"
      :contratto-id="contratti[selectedContract].id"
    />
  </div>
  <div v-else>
    <router-link
      class="routerLink"
      :to="{ path: `/details/${proprietarioId}/contracts/add` }"
    >
      <el-button type="success" plain class="choose-btn">
        Aggiungi Contratto</el-button
      >
    </router-link>
  </div>
</template>

<script>
import { ref } from 'vue';
import CentraliRead from './CentraliRead.vue';
import { useRoute } from 'vue-router';
import detailsAPI from '../services/detailsAPI';
export default {
  name: 'ContrattoRead',
  components: { CentraliRead },
  props: {
    contratti: {
      type: Array,
      default: () => [
        {
          centrali: [
            { impianti: [{ sezioni: [{ inverters: [{ moduli: [] }] }] }] },
          ],
        },
      ],
    },
  },
  setup() {
    let selectedContract = ref(0);
    let selectContract = (index) => {
      selectedContract.value = index;
    };
    const route = useRoute();
    const proprietarioId = route.params.id;

    const openFile = (path) => {
      detailsAPI.display(path).then((res) => {
        console.log(res.data);
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const objectUrl = window.URL.createObjectURL(blob);
        window.open(objectUrl);
      });
    };

    return {
      selectedContract,
      selectContract,
      proprietarioId,
      openFile,
    };
  },
  computed: {
    centrali() {
      return this.contratti[this.selectedContract].centrali;
    },
  },
};
</script>

<style>
.choose-section {
  width: 100%;
}
.choose-btn {
  width: 98%;
  height: 30px;
  padding: auto;
  margin-left: 0px !important;
}

.header-implant {
  margin-top: 100px;
}

.action-btn {
  margin-top: 30px;
}
</style>
