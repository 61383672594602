<template>
  <div v-if="centrali[selectedCentral] !== undefined">
    <h1 class="header-implant">Centrali</h1>

    <el-row :gutter="0">
      <el-col :span="4">
        <div class="grid-content" />
        <h4 style="text-align: left; margin-top: 0px">Seleziona centrale</h4>
        <div id="selection" class="choose-section">
          <el-button
            v-for="(centrale, index) in centrali"
            :key="centrale.id"
            type="primary"
            class="choose-btn"
            :plain="!selectedCentral == index"
            @click="selectCentral(index)"
          >
            {{ centrale.nomeCentrale }}
          </el-button>
        </div>
        <div class="action-btn">
          <router-link
            class="routerLink"
            :to="{
              path: `/details/${proprietarioId}/contratto/${contrattoId}/centrali/add`,
            }"
          >
            <el-button type="success" plain class="choose-btn">
              Aggiungi
            </el-button>
          </router-link>
          <router-link
            class="routerLink"
            :to="{
              path: `/details/${proprietarioId}/contratto/${contrattoId}/centrali/${centrali[selectedCentral].id}/edit`,
            }"
          >
            <el-button type="info" plain class="choose-btn">
              Modifica
            </el-button>
          </router-link>
        </div>
      </el-col>
      <el-col :span="20">
        <el-descriptions title="Dettagli Centrale" :column="2" border>
          <el-descriptions-item
            label="Nome centrale"
            label-align="right"
            align="left"
            label-class-name="my-label"
            class-name="my-content"
          >
            {{ centrali[selectedCentral].nomeCentrale }}
          </el-descriptions-item>

          <el-descriptions-item
            label="KW Centrale"
            label-align="right"
            align="left"
          >
            {{ centrali[selectedCentral].kwimp }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Indirizzo"
            label-align="right"
            align="left"
          >
            {{ centrali[selectedCentral].indirizzo }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Coordinate"
            label-align="right"
            align="left"
          >
            <span @click="copy(centrali[selectedCentral].gps)">{{
              centrali[selectedCentral].gps
            }}</span>
          </el-descriptions-item>

          <el-descriptions-item
            label="Tag"
            label-align="right"
            align="left"
            :span="2"
          >
            <el-tag
              v-if="centrali[selectedCentral].bt"
              type="success"
              class="mx-1"
            >
              Bassa Tensione
            </el-tag>
            <el-tag
              v-if="centrali[selectedCentral].mt"
              type="success"
              class="mx-1"
            >
              Media Tensione
            </el-tag>
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>

    <ImpiantiRead
      :impianti="impianti"
      :centrale-id="centrali[selectedCentral].id"
    />
  </div>
  <div v-else>
    <router-link
      class="routerLink"
      :to="{
        path: `/details/${proprietarioId}/contratto/${contrattoId}/centrali/add`,
      }"
    >
      <el-button type="success" plain class="choose-btn">
        Aggiungi Centrale</el-button
      >
    </router-link>
  </div>
</template>

<script>
import { ref } from 'vue';
import ImpiantiRead from './ImpiantiRead';
import { useRoute } from 'vue-router';
import useClipboard from 'vue-clipboard3';
import { ElMessage } from 'element-plus';
export default {
  name: 'CentraliRead',
  components: { ImpiantiRead },
  props: {
    centrali: { type: Array, default: () => [{ impianti: [{ sezioni: [] }] }] },
    contrattoId: { type: Number, default: () => 0 },
  },
  setup() {
    const { toClipboard } = useClipboard();
    const route = useRoute();
    const proprietarioId = route.params.id;
    let selectedCentral = ref(0);
    let selectCentral = (index) => {
      selectedCentral.value = index;
    };
    let copy = async (text) => {
      try {
        await toClipboard(text);
        ElMessage('Copiato nella Clipboard');
      } catch (e) {
        console.error(e);
      }
    };
    return {
      selectedCentral,
      selectCentral,
      proprietarioId,
      copy,
    };
  },
  computed: {
    impianti() {
      return this.centrali[this.selectedCentral].impianti;
    },
    gps() {
      if (this.centrali[this.selectedCentral].gps)
        return this.centrali[this.selectedCentral].gps.replaceAll('?', "'");
      return '';
    },
  },
};
</script>

<style></style>
