<template>
  <h1>Nuova Centrale</h1>

  <el-form :model="centrale" label-width="240px">
    <el-form-item label="Nome Centrale">
      <el-input v-model="centrale.nomeCentrale" />
    </el-form-item>
    <el-form-item label="Indirizzo">
      <el-input v-model="centrale.indirizzo" />
    </el-form-item>
    <el-form-item label="Coordinate">
      <el-input v-model="centrale.gps" />
    </el-form-item>
    <el-form-item label="KW Centrale">
      <el-input-number v-model="centrale.kwimp" />
    </el-form-item>
    <el-form-item label="Bassa Tensione">
      <el-switch v-model="centrale.bt" @change="changebt" />
    </el-form-item>
    <el-form-item label="Media Tensione">
      <el-switch v-model="centrale.mt" @change="changemt" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> Crea </el-button>
      <el-button @click="annulla"> Annulla </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { reactive, toRaw } from 'vue';
import detailsAPI from '../../services/detailsAPI';
import { ElMessage } from 'element-plus';
export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const route = useRoute();
    const contrattoId = route.params.contrattoId;
    const proprietarioId = route.params.id;

    const centrale = reactive({
      nomeCentrale: '',
      indirizzo: '',
      gps: '',
      kwimp: 0,
      mt: false,
      bt: true,
      fkContratto: contrattoId,
    });

    //Inizializzo i pulsanti
    const onSubmit = () => {
      if (centrale.nomeCentrale == '') {
        ElMessage({
          message: 'Inserisci almeno il nome della centrale',
          type: 'warning',
        });
        return;
      }
      addNewCentrale(toRaw(centrale)).then((response) => {
        console.log(response);
        if (response.status === 201) {
          console.log('Centrale aggiunta!');
          router.push(`/details/${proprietarioId}`);
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const annulla = () => {
      router.push(`/details/${proprietarioId}`);
    };
    const convertBooleanFields = (inputObj) => {
  // Helper function to perform the conversion
  function convert(obj) {
    let newObj = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'boolean') {
          newObj[key] = obj[key] ? 1 : 0;
        } else if (typeof obj[key] === 'object' && obj[key] !== null && !(obj[key] instanceof Date)) {
          newObj[key] = convert(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
    return newObj;
  }

  // Return the converted object
  return convert(inputObj);
}
    // Funzioni per aggiungere nuovo
    const addNewCentrale = async (centrale) => {
      console.log(centrale);
      let response = {};
      try {
        let centrale2 = convertBooleanFields(centrale)
        response = await detailsAPI.addCentrale(centrale2);
        console.log(response);
        return response;
      } catch {
        console.log('error');
      }
    };

    const changebt = () => {
      centrale.mt = !centrale.bt;
    };
    const changemt = () => {
      centrale.bt = !centrale.mt;
    };

    return {
      proprietarioId,
      centrale,
      onSubmit,
      addNewCentrale,
      changebt,
      changemt,
      annulla,
    };
  },
};
</script>
