<template>
  <h1>Nuovo Proprietario</h1>

  <el-form :model="proprietario" label-width="240px">
    <!-- fake fields are a workaround for chrome autofill getting the wrong fields -->
    <input style="display: none" type="text" name="fakeusernameremembered" />
    <input
      style="display: none"
      type="password"
      name="fakepasswordremembered"
    />

    <h3>Dati Cliente</h3>
    <el-switch
      v-model="tipo"
      class="mb-2"
      active-text="Azienda"
      inactive-text="Privato"
    />
    <el-form-item label="Ragione Sociale" v-if="tipo">
      <el-input v-model="proprietario.ragione" />
    </el-form-item>
    <el-form-item label="Nome" v-if="!tipo">
      <el-input v-model="proprietario.nome" />
    </el-form-item>
    <el-form-item label="Cognome" v-if="!tipo">
      <el-input v-model="proprietario.cognome" />
    </el-form-item>

    <el-form-item label="Partita IVA" v-if="tipo">
      <el-input v-model="proprietario.piva" />
    </el-form-item>
    <el-form-item label="Codice Fiscale" v-if="!tipo">
      <el-input v-model="proprietario.cf" />
    </el-form-item>
    <el-form-item label="IBAN">
      <el-input v-model="proprietario.iban" />
    </el-form-item>
    <h3>Sede Legale</h3>
    <el-form-item label="Via">
      <el-input v-model="proprietario.viaSedelegale" />
    </el-form-item>
    <el-form-item label="Numero Civico">
      <el-input v-model="proprietario.numcivSedelegale" />
    </el-form-item>
    <el-form-item label="Comune">
      <el-input v-model="proprietario.comunSedelegale" />
    </el-form-item>
    <el-form-item label="Provincia">
      <el-input v-model="proprietario.provinSedelegale" />
    </el-form-item>
    <el-form-item label="CAP">
      <el-input v-model="proprietario.capSedelegale" />
    </el-form-item>
    <h3>Sede Operativa</h3>
    <el-form-item label="Via">
      <el-input v-model="proprietario.viaSedeoperativa" />
    </el-form-item>
    <el-form-item label="Numero Civico">
      <el-input v-model="proprietario.numcivSedeoperativa" />
    </el-form-item>
    <el-form-item label="Comune">
      <el-input v-model="proprietario.comunSedeoperativa" />
    </el-form-item>
    <el-form-item label="Provincia">
      <el-input v-model="proprietario.provinSedeoperativa" />
    </el-form-item>
    <el-form-item label="CAP">
      <el-input v-model="proprietario.capSedeoperativa" />
    </el-form-item>
    <el-button @click="copy">Copia da sede legale</el-button>
    <h3>GSE</h3>
    <el-form-item label="Username GSE">
      <el-input v-model="proprietario.userGse" />
    </el-form-item>
    <el-form-item label="Password GSE">
      <el-input
        type="password"
        placeholder="Inserisci password"
        show-password
        v-model="proprietario.passGse"
      />
    </el-form-item>
    <h3>Terna</h3>
    <el-form-item label="Username Terna">
      <el-input v-model="proprietario.userTerna" />
    </el-form-item>
    <el-form-item label="Password Terna">
      <el-input
        type="password"
        placeholder="Inserisci password"
        show-password
        v-model="proprietario.passTerna"
      />
    </el-form-item>
    <h3>Enel</h3>
    <el-form-item label="Username Enel">
      <el-input v-model="proprietario.userEnel" />
    </el-form-item>
    <el-form-item label="Password Enel">
      <el-input
        type="password"
        placeholder="Inserisci password"
        show-password
        v-model="proprietario.passEnel"
      />
    </el-form-item>
    <el-form-item label="Note">
      <el-input type="textarea" v-model="proprietario.codSicEnel" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit"> Modifica </el-button>
      <el-button @click="annulla"> Annulla </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import { onMounted, reactive, toRaw, ref } from 'vue';
import detailsAPI from '../../services/detailsAPI';

export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const route = useRoute();
    const proprietarioId = route.params.id;
    const tipo = ref(true);
    const proprietario = reactive({
      piva: '',
      cf: '',
      iban: '',
      viaSedelegale: '',
      numcivSedelegale: '',
      comunSedeoperativa: '',
      provinSedeoperativa: '',
      capSedeoperativa: '',
      userGse: '',
      passGse: '',
      userTerna: '',
      passTerna: '',
      userEnel: '',
      passEnel: '',
      codSicEnel: '',
      nome: '',
      cognome: '',
      comunSedelegale: '',
      provinSedelegale: '',
      capSedelegale: '',
      viaSedeoperativa: '',
      numcivSedeoperativa: '',
      ragione: '',
    });

    let copy = () => {
      proprietario.capSedeoperativa = proprietario.capSedelegale;
      proprietario.viaSedeoperativa = proprietario.viaSedelegale;
      proprietario.comunSedeoperativa = proprietario.comunSedelegale;
      proprietario.numcivSedeoperativa = proprietario.numcivSedelegale;
      proprietario.provinSedeoperativa = proprietario.provinSedelegale;
    };

    //Inizializzo i pulsanti
    const onSubmit = () => {
      editProprietario(proprietarioId, toRaw(proprietario)).then((response) => {
        if (response.status === 200) {
          console.log('Proprietario modificato!');
          router.push(`/details/` + proprietarioId);
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const annulla = () => {
      router.push(`/details/` + proprietarioId);
    };

    // Funzioni per modificare
    const editProprietario = async (proprietarioId, proprietario) => {
      let response = {};
      try {
        response = await detailsAPI.editProprietario(
          proprietarioId,
          proprietario
        );
        return response;
      } catch {
        console.log('error');
      }
    };

    //Riempio una volta caricata la pagina
    const getProprietario = async (id) => {
      let response = {};
      try {
        console.log(id);
        response = await detailsAPI.getSimpleProprietario(id);
        return response.data;
      } catch {
        console.log('error');
      }
    };

    onMounted(() => {
      getProprietario(proprietarioId).then((proprietarioDetail) => {
        if (proprietarioDetail) {
          proprietario.piva = proprietarioDetail.piva;
          proprietario.cf = proprietarioDetail.cf;
          proprietario.iban = proprietarioDetail.iban;
          proprietario.viaSedelegale = proprietarioDetail.via_sedelegale;
          proprietario.numcivSedelegale = proprietarioDetail.numciv_sedelegale;
          proprietario.comunSedeoperativa =
            proprietarioDetail.comun_sedeoperativa;
          proprietario.provinSedeoperativa =
            proprietarioDetail.provin_sedeoperativa;
          proprietario.capSedeoperativa = proprietarioDetail.cap_sedeoperativa;
          proprietario.userGse = proprietarioDetail.usergse;
          proprietario.passGse = proprietarioDetail.passgse;
          proprietario.userTerna = proprietarioDetail.userterna;
          proprietario.passTerna = proprietarioDetail.passterna;
          proprietario.userEnel = proprietarioDetail.userenel;
          proprietario.passEnel = proprietarioDetail.passenel;
          proprietario.codSicEnel = proprietarioDetail.cod_sicenel;
          proprietario.nome = proprietarioDetail.nome;
          proprietario.cognome = proprietarioDetail.cognome;
          proprietario.comunSedelegale = proprietarioDetail.comun_sedelegale;
          proprietario.provinSedelegale = proprietarioDetail.provin_sedelegale;
          proprietario.capSedelegale = proprietarioDetail.cap_sedelegale;
          proprietario.viaSedeoperativa = proprietarioDetail.via_sedeoperativa;
          proprietario.numcivSedeoperativa =
            proprietarioDetail.numciv_sedeoperativa;
          proprietario.ragione = proprietarioDetail.ragione;
          console.log(proprietario);
        }
      });
    });

    //TODO: Ho gia preso l'id, devo fare funzione che copia in automatico prendendo dal db e cambiare l'on submit

    return {
      proprietario,
      tipo,
      onSubmit,
      annulla,
      copy,
    };
  },
};
</script>
