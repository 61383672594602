<template>
  <img src="../assets/logo.png" class="logo" />
  <h1>Ricerca inversa</h1>

  <div>
    <el-form label-width="240px">
      <el-form-item label="Cerca">
        <el-autocomplete
          v-model="input"
          value-key="cerca"
          :fetch-suggestions="querySearch"
          :trigger-on-focus="false"
          clearable
          placeholder="Cosa vuoi cercare"
          class="autocomplete"
        />
      </el-form-item>
      <el-form-item>
        <el-radio-group v-model="cosa">
          <el-radio :label="'Contatore'">Contatore</el-radio>
          <el-radio :label="'Interfaccia'">Interfaccia</el-radio>
          <el-radio :label="'Inverter'">Inverter</el-radio>
          <el-radio :label="'Modulo'">Modulo</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-switch
          v-model="tipo"
          size="large"
          active-text="Marca"
          inactive-text="Modello"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit"> Cerca </el-button>
      </el-form-item>
    </el-form>
  </div>

  <div class="mt-4">
    <el-table :data="tableGears" height="300" style="width: 100%">
      <el-table-column prop="marca" label="Marca" />
      <el-table-column prop="modello" label="Modello" />
      <el-table-column prop="nomeCentrale" label="Centrale" />
      <el-table-column prop="proprietario" label="Proprietario" />
      <el-table-column label="Azioni">
        <template #default="scope">
          <router-link
            :to="{ path: `/details/${scope.row.id}` }"
            class="routerLink"
          >
            <el-button size="small"> Visualizza </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { Search } from '@element-plus/icons-vue';
import { ref, onMounted } from 'vue';
import searchAPI from '../services/searchAPI';

export default {
  components: [Search],
  setup() {
    const input = ref('');
    const tableGears = ref([]);
    const cosa = ref('Contatore');
    const tipo = ref('');

    const loadCustomers = async () => {};
    const querySearch = (queryString, cb) => {
      let array;
      if (cosa.value == 'Contatore') {
        if (tipo.value) {
          array = marcaContatore;
        } else {
          array = modelloContatore;
        }
      }
      if (cosa.value == 'Interfaccia') {
        if (tipo.value) {
          array = marcaInterfaccia;
        } else {
          array = modelloInterfaccia;
        }
      }
      if (cosa.value == 'Inverter') {
        if (tipo.value) {
          array = marcaInverter;
        } else {
          array = modelloInverter;
        }
      }
      if (cosa.value == 'Modulo') {
        if (tipo.value) {
          array = marcaModulo;
        } else {
          array = modelloModulo;
        }
      }

      const output = queryString
        ? array.filter(createFilter(queryString))
        : array;

      let results = [];
      output.forEach((item) => {
        results.push({ cerca: Object.values(item)[0] });
      });

      // call callback function to return suggestions
      cb(results);
    };

    const createFilter = (queryString) => {
      return (item) => {
        return (
          Object.values(item)[0]
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) == 0
        );
      };
    };
    const onSubmit = () => {
      tableGears.value = [];
      searchAPI
        .filterGears({
          tipo: tipo.value,
          cosa: cosa.value,
          input: input.value,
        })
        .then((result) => {
          try {
            result.data.forEach((row) => {
              let tableRow = {
                id: row.id_proprietario,
                marca: row.marca,
                modello: row.modello,
                nomeCentrale: row.nome_centrale,
                proprietario: row.ragione + row.nome + ' ' + row.cognome,
              };
              tableGears.value.push(tableRow);
            });
          } catch {
            console.log('error');
          }
        });
    };

    let marcaInverter = [];
    let modelloInverter = [];
    let marcaModulo = [];
    let modelloModulo = [];
    let marcaContatore = [];
    let modelloContatore = [];
    let marcaInterfaccia = [];
    let modelloInterfaccia = [];

    onMounted(() => {
      searchAPI.getAutocompleteInverter().then((result) => {
        marcaInverter = result.data.marca;
        modelloInverter = result.data.modello;
      });

      searchAPI.getAutocompleteModule().then((result) => {
        marcaModulo = result.data.marca;
        modelloModulo = result.data.modello;
      });

      searchAPI.getAutocompleteSezione().then((result) => {
        marcaContatore = result.data.marcaContatore;
        modelloContatore = result.data.modelloContatore;
        marcaInterfaccia = result.data.marcaInterfaccia;
        modelloInterfaccia = result.data.modelloInterfaccia;
      });
    });

    loadCustomers();

    return {
      input,
      cosa,
      tipo,
      tableGears,
      onSubmit,
      querySearch,
    };
  },
};
</script>

<style>
.logo {
  width: 100px;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
