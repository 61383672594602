<template>
  <h1>Modifica {{ centrale.nomeCentrale }}</h1>

  <el-form
    :model="centrale"
    label-width="240px"
  >
    <el-form-item label="Nome Centrale">
      <el-input v-model="centrale.nomeCentrale" />
    </el-form-item>
    <el-form-item label="Indirizzo">
      <el-input v-model="centrale.indirizzo" />
    </el-form-item>
    <el-form-item label="Coordinate">
      <el-input v-model="centrale.gps" />
    </el-form-item>
    <el-form-item label="KW Centrale">
      <el-input-number v-model="centrale.kwimp" />
    </el-form-item>
    <el-form-item label="Bassa Tensione">
      <el-switch
        v-model="centrale.bt"
        :active-value="1"
        :inactive-value="0"
        @change="changebt"
      />
    </el-form-item>
    <el-form-item label="Media Tensione">
      <el-switch
        v-model="centrale.mt"
        :active-value="1"
        :inactive-value="0"
        @change="changemt"
      />
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
      >
        Modifica
      </el-button>
      <el-button @click="annulla">
        Annulla
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, reactive, toRaw } from 'vue';
import detailsAPI from '../../services/detailsAPI';

export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const route = useRoute();
    const proprietarioId = route.params.id;
    const centraleId = route.params.centraleId;

    const centrale = reactive({
      id: 0,
      nomeCentrale: '',
      indirizzo: '',
      gps: '',
      kwimp: 0,
      mt: false,
      bt: true,
      fkContratto: 0,
    });

    //Inizializzo i pulsanti
    const onSubmit = () => {
      editCentrale(centraleId, toRaw(centrale)).then((response) => {
        if (response.status === 200) {
          console.log('Centrale modificata!');
          router.push(`/details/${proprietarioId}`);
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const annulla = () => {
      router.push(`/details/${proprietarioId}`);
    };

    //Riempio una volta xaricata la pagina
    const getCentrale = async (centraleId) => {
      let response = {};
      try {
        response = await detailsAPI.getCentrale(centraleId);
        return response.data;
      } catch {
        console.log('error');
      }
    };

    onMounted(() => {
      getCentrale(centraleId).then((centraleDetail) => {
        if (centraleDetail) {
          centrale.id = centraleDetail.id;
          centrale.nomeCentrale = centraleDetail.nomeCentrale;
          centrale.indirizzo = centraleDetail.indirizzo;
          centrale.gps = centraleDetail.gps;
          centrale.kwimp = centraleDetail.kwimp;
          centrale.mt = centraleDetail.mt;
          centrale.bt = centraleDetail.bt;
          centrale.fkContratto = centraleDetail.fkContratto;
        }
      });
    });
    const convertBooleanFields = (inputObj) => {
  // Helper function to perform the conversion
  function convert(obj) {
    let newObj = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'boolean') {
          newObj[key] = obj[key] ? 1 : 0;
        } else if (typeof obj[key] === 'object' && obj[key] !== null && !(obj[key] instanceof Date)) {
          newObj[key] = convert(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      }
    }
    return newObj;
  }

  // Return the converted object
  return convert(inputObj);
}
    // Funzioni per modificare
    const editCentrale = async (id, centrale) => {
      let response = {};
      try {
        let centrale2 = convertBooleanFields(centrale)
        response = await detailsAPI.patchCentrale(id, centrale2);
        return response;
      } catch {
        console.log('error');
      }
    };

    const changebt = () => {
      centrale.mt = !centrale.bt;
    };
    const changemt = () => {
      centrale.bt = !centrale.mt;
    };

    return {
      proprietarioId,
      centrale,
      onSubmit,
      changebt,
      changemt,
      annulla,
    };
  },
};
</script>
