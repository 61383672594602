<template>
  <h1>Ciao {{ utente }}</h1>
  <br />
  <h2>Finisci i fogli lavoro in corso:</h2>
  <el-table :data="worksheets" max-height="500" style="width: 100%">
    <el-table-column prop="proprietario" label="Cliente" />
    <el-table-column prop="data" label="Data" />
    <el-table-column label="Azioni">
      <template #default="id">
        <router-link :to="{ path: '/worksheet/' + id.row.id + '/edit' }">
          <el-button size="small"> Continua </el-button>
        </router-link>
      </template>
    </el-table-column>
  </el-table>

  <h2>Altrimenti compila un nuovo foglio di lavoro:</h2>

  <router-link to="/select-client">
    <el-button type="primary">Nuovo foglio di lavoro</el-button></router-link
  >
  <br />
  <br />

  <h2>Ultimi fogli lavoro inseriti</h2>
  <el-table :data="lastWorksheets" max-height="500" style="width: 100%">
    <el-table-column prop="proprietario" label="Cliente" />
    <el-table-column prop="data" label="Data" />
    <el-table-column label="Azioni">
      <template #default="id">
        <el-button size="small" @click="seeWorksheets(id.row.id)">
          Visualizza Foglio di Lavoro
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { isLoggedStore } from '@/store/logged';
import detailsApi from '@/services/detailsAPI';
import { ref } from 'vue';

export default {
  setup() {
    let store = isLoggedStore();
    let utente = store.nome;
    let username = store.user;
    let worksheets = ref([]);
    let lastWorksheets = ref([]);
    const loadDraftWorksheet = async () => {
      let notInvoicedWorksheet = (
        await detailsApi.loadDraftWorksheets(username)
      ).data;

      for (let worksheet of notInvoicedWorksheet) {
        worksheets.value.push({
          id: worksheet.id,
          data: new Date(worksheet.date).toLocaleDateString('IT-it'),
          proprietario: worksheet.proprietario,
        });
      }

      let lastWorksheetDatas = (await detailsApi.loadLastWorksheets(username))
        .data;

      for (let worksheet of lastWorksheetDatas) {
        lastWorksheets.value.push({
          id: worksheet.id,
          data: new Date(worksheet.date).toLocaleDateString('IT-it'),
          proprietario: worksheet.proprietario,
        });
      }
    };
    let seeWorksheets = async (id) => {
      await detailsApi.printWorksheet(id);
    };
    //Riempio worksheet
    loadDraftWorksheet();
    return { utente, worksheets, lastWorksheets, seeWorksheets };
  },
};
</script>
