<template>
  <h1 class="header-implant">Impianti</h1>
  <div v-if="impianti[selectedImpianto] !== undefined">
    <el-row :gutter="0">
      <el-col :span="4">
        <div class="grid-content" />
        <h4 style="text-align: left; margin-top: 0px">Seleziona Impianto</h4>
        <div id="selection" class="choose-section">
          <el-button
            v-for="(impianto, index) in impianti"
            :key="impianto.id"
            type="primary"
            class="choose-btn"
            :plain="!selectedImpianto == index"
            @click="selectImpianto(index)"
          >
            {{ impianto.nomeImpianto }}
          </el-button>
        </div>
        <div class="action-btn">
          <router-link
            class="routerLink"
            :to="{
              path: `/details/${proprietarioId}/centrale/${centraleId}/impianti/add`,
            }"
          >
            <el-button type="success" plain class="choose-btn">
              Aggiungi
            </el-button>
          </router-link>
          <router-link
            class="routerLink"
            :to="{
              path: `/details/${proprietarioId}/centrale/${centraleId}/impianti/${impianti[selectedImpianto].id}/edit`,
            }"
          >
            <el-button type="info" plain class="choose-btn">
              Modifica
            </el-button>
          </router-link>
        </div>
      </el-col>
      <el-col :span="20">
        <el-descriptions title="Dettagli Impianto" :column="3" border>
          <el-descriptions-item
            label="Nome impianto"
            label-align="right"
            align="left"
            label-class-name="my-label"
            class-name="my-content"
            :span="3"
          >
            {{ impianti[selectedImpianto].nomeImpianto }}
          </el-descriptions-item>

          <el-descriptions-item
            label="IP Monitoraggio"
            label-align="right"
            align="left"
          >
            <span @click="copy(impianti[selectedImpianto].ip)">{{
              impianti[selectedImpianto].ip
            }}</span>
          </el-descriptions-item>
          <div v-if="isAdmin">
            <el-descriptions-item
              label="Username"
              label-align="right"
              align="left"
            >
              <span @click="copy(impianti[selectedImpianto].nomeuser)">
                {{ impianti[selectedImpianto].nomeuser }}</span
              >
            </el-descriptions-item>
            <el-descriptions-item
              label="Password"
              label-align="right"
              align="left"
            >
              <span
                @click="copy(impianti[selectedImpianto].password)"
                v-if="impianti[selectedImpianto].password"
              >
                ••••••••
              </span>
            </el-descriptions-item>
          </div>
          <el-descriptions-item label="GSE" label-align="right" align="left">
            {{ impianti[selectedImpianto].gse }}
          </el-descriptions-item>
          <el-descriptions-item
            label="GSE Incentivi"
            label-align="right"
            align="left"
          >
            {{ impianti[selectedImpianto].gseIncentivi }}
          </el-descriptions-item>
          <el-descriptions-item
            label="GSE Vendita"
            label-align="right"
            align="left"
          >
            {{ impianti[selectedImpianto].gseVendita }}
          </el-descriptions-item>

          <el-descriptions-item
            label="CENSIMP"
            label-align="right"
            align="left"
          >
            {{ impianti[selectedImpianto].censimp }}
          </el-descriptions-item>
          <el-descriptions-item label="POD" label-align="right" align="left">
            {{ impianti[selectedImpianto].pod }}
          </el-descriptions-item>
          <el-descriptions-item label="DITTA" label-align="right" align="left">
            {{ impianti[selectedImpianto].ditta }}
          </el-descriptions-item>

          <el-descriptions-item
            label="Note"
            label-align="right"
            align="left"
            :span="3"
          >
            {{ impianti[selectedImpianto].note }}
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>

    <SezioniRead
      :sezioni="sezioni"
      :impianto-id="impianti[selectedImpianto].id"
    />
  </div>

  <div v-else>
    <router-link
      class="routerLink"
      :to="{
        path: `/details/${proprietarioId}/centrale/${centraleId}/impianti/add`,
      }"
    >
      <el-button type="success" plain class="choose-btn">
        Aggiungi Impianto</el-button
      >
    </router-link>
  </div>
</template>

<script>
import { ref } from 'vue';
import SezioniRead from './sezioniRead.vue';
import { useRoute } from 'vue-router';
import useClipboard from 'vue-clipboard3';
import { ElMessage } from 'element-plus';
import crypto from '@/services/crypto';
import { isLoggedStore } from '@/store/logged';

export default {
  name: 'ImpiantiRead',
  components: { SezioniRead },
  props: {
    impianti: { type: Array, default: () => [{ sezioni: [] }] },
    centraleId: { type: Number, default: () => 0 },
  },
  setup() {
    const store = isLoggedStore();
    let isAdmin = crypto.decipher(store.tipo) == 'admin';
    const { toClipboard } = useClipboard();

    let route = useRoute();
    const proprietarioId = route.params.id;

    let selectedImpianto = ref(0);
    let selectImpianto = (index) => {
      selectedImpianto.value = index;
    };

    let copy = async (text) => {
      try {
        await toClipboard(text);
        ElMessage('Copiato nella Clipboard');
      } catch (e) {
        console.error(e);
      }
    };

    return {
      selectedImpianto,
      selectImpianto,
      proprietarioId,
      isAdmin,
      copy,
    };
  },
  computed: {
    sezioni() {
      return this.impianti[this.selectedImpianto].sezioni;
    },
  },
};
</script>

<style></style>
