<template>
  <div class="common-layout">
    <el-container :key="router.currentRoute">
      <el-aside v-if="store.viewMenu == true" width="200px">
        <el-menu class="el-menu-vertical-demo max-height">
          <router-link
            v-if="tipo == 'admin' || tipo == 'user'"
            class="routerLink"
            to="/find-client"
          >
            <el-menu-item index="1">
              <el-icon><user /></el-icon>
              <span>Cerca clienti</span>
            </el-menu-item>
          </router-link>
          <router-link
            v-if="tipo == 'admin'"
            class="routerLink"
            to="/aggiungi-preventivo"
          >
            <el-menu-item index="2">
              <el-icon><Finished /></el-icon>
              <span>Accetta Fogli Lavoro</span>
            </el-menu-item>
          </router-link>
          <router-link
            v-if="tipo == 'segreteria'"
            class="routerLink"
            to="/administration-home"
          >
            <el-menu-item index="3">
              <el-icon><Postcard /></el-icon>
              <span>Amministrazione</span>
            </el-menu-item>
          </router-link>
          <router-link
            v-if="tipo == 'tecnico'"
            class="routerLink"
            to="/tecnician-home"
          >
            <el-menu-item index="4">
              <el-icon><Postcard /></el-icon>
              <span>Crea Foglio Lavoro</span>
            </el-menu-item>
          </router-link>

          <router-link
            v-if="tipo == 'admin' || tipo == 'user'"
            class="routerLink"
            to="/scadenze"
          >
            <el-menu-item index="5">
              <el-icon><turn-off /></el-icon>
              <span>Scadenze</span>
            </el-menu-item>
          </router-link>

          <router-link
            v-if="tipo == 'admin' || tipo == 'user'"
            class="routerLink"
            to="/ricerca-inversa"
          >
            <el-menu-item index="6">
              <el-icon><TakeawayBox /></el-icon>
              <span>Ricerca Inversa</span>
            </el-menu-item>
          </router-link>

          <router-link
            v-if="tipo == 'admin' || tipo == 'user'"
            class="routerLink"
            to="/contratti"
          >
            <el-menu-item index="7">
              <el-icon><Postcard /></el-icon>
              <span>Filtra Contratti</span>
            </el-menu-item>
          </router-link>
          <router-link
            v-if="tipo == 'admin' || tipo == 'segreteria' || tipo == 'user'"
            class="routerLink"
            to="/filtra-fogli-lavoro"
          >
            <el-menu-item index="8">
              <el-icon><Filter /></el-icon>
              <span>Filtra Fogli Lavoro</span>
            </el-menu-item>
          </router-link>

          <el-menu-item index="9" @click="logout">
            <el-icon><DocumentDelete /></el-icon>
            <span>Logout</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { isLoggedStore } from './store/logged';
import crypto from './services/crypto';
export default {
  name: 'App',
  components: {},
  setup() {
    const store = isLoggedStore();
    const router = useRouter();
    const logout = () => {
      store.isLogged = false;
      store.viewMenu = false;
      store.nome = '';
      store.tipo = '';
      router.push('/login');
    };

    let tipo = crypto.decipher(store.tipo);

    return {
      logout,
      store,
      tipo,
      router,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}

.routerLink {
  text-decoration: none;
}

.max-height {
  min-height: 100vh;
  height: 100%;
}
</style>
