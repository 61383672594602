<template>
  <h1>Seleziona un cliente</h1>
  <div class="mt-4">
    <el-input v-model="input" placeholder="Inserisci nome cliente">
      <template #append>
        <el-button>
          <el-icon><search /></el-icon>
        </el-button>
      </template>
    </el-input>

    <el-table
      :data="filteredTable"
      table-layout="auto"
      height="300"
      style="width: 90%"
    >
      <el-table-column prop="name" label="Denominazione" />
      <el-table-column label="Badge">
        <template #default="scope">
          <router-link
            :to="{ path: `/test/${scope.row.id}` }"
            class="routerLink"
          >
            <el-button size="small" @click="handleClick(scope.row.id)">
              Seleziona
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>

    <h3>Il cliente non è presente? Prosegui con un'aggiunta manuale:</h3>
    <router-link :to="{ path: `/test/manual` }" class="routerLink">
      <el-button type="success" size="large">Aggiungi manualmente</el-button>
    </router-link>
  </div>
</template>

<script>
import { Search } from '@element-plus/icons-vue';
import { ref, computed } from 'vue';
import searchAPI from './../../../services/searchAPI';
import { isLoggedStore } from '@/store/logged';

export default {
  components: [Search],
  setup() {
    const store = isLoggedStore();
    console.log(store);
    const input = ref('');
    const tableData = ref([]);

    const handleClick = (id) => {
      console.log('click ' + id);
    };

    const loadCustomers = async () => {
      try {
        const response = await searchAPI.getCustomers();
        response.data.forEach((cliente) => {
          let tableRow = {
            id: cliente.id,
            name: (
              cliente.ragione +
              cliente.nome +
              ' ' +
              cliente.cognome
            ).trim(),
          };
          tableData.value.push(tableRow);
        });
      } catch {
        console.log('error');
      }
    };
    loadCustomers();

    const filteredTable = computed(function () {
      return tableData.value.filter((row) =>
        row.name.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    return {
      input,
      tableData,
      filteredTable,
      handleClick,
    };
  },
};
</script>

<style>
.logo {
  width: 100px;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
