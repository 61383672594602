import { createRouter, createWebHistory } from 'vue-router';
import SearchPage from '../Views/SearchPage.vue';
import ClientDetailPage from '../Views/ClientDetailPage.vue';
import AddContractPage from '../Views/contratti/AddContractPage.vue';
import EditContractPage from '../Views/contratti/EditContractPage.vue';
import AddCentralePage from '../Views/centrali/AddCentralePage.vue';
import EditCentralePage from '../Views/centrali/EditCentralePage.vue';
import AddImpiantiPage from '../Views/impianti/AddImpiantiPage.vue';
import EditImpiantiPage from '../Views/impianti/EditImpiantiPage.vue';
import AddSezionePage from '../Views/sezioni/AddSezionePage.vue';
import EditSezionePage from '../Views/sezioni/EditSezionePage.vue';
import AddInverterPage from '../Views/inverters/AddInverterPage.vue';
import EditInverterPage from '../Views/inverters/EditInverterPage.vue';
import AddModuloPage from '../Views/moduli/AddModuloPage.vue';
import EditModuloPage from '../Views/moduli/EditModuloPage.vue';
import ScadenzePage from '../Views/ScadenzePage.vue';
import LoginPage from '../Views/LoginPage.vue';
import AddWorksheetsPage from '../Views/Tecnici/WorkSheets/AddWorkSheetsPage.vue';
import TecnicianHomePage from '../Views/Tecnici/HelloTecnicoPage.vue';
import AdministrationHomePage from '../Views/Segreteria/InvoicePage.vue';
import SelectClientPage from '../Views/Tecnici/WorkSheets/SelectClientPage.vue';
import PriceCalculator from '../Views/Segreteria/PriceCalculator.vue';
import FiltraContrattiPage from '../Views/FiltraContrattiPage.vue';
import FilterWorksheetPage from '../Views/Segreteria/FilterWorksheetPage.vue';
import AddProprietarioPage from '../Views/proprietari/AddProprietarioPage.vue';
import EditProprietarioPage from '../Views/proprietari/EditProprietarioPage.vue';
import AddReferentePage from '../Views/referenti/AddReferentePage.vue';
import EditReferentePage from '../Views/referenti/EditReferentePage.vue';
import ErrorePermessiPage from '../Views/errors/PermissionErrorPage.vue';
import EditWorksheetsPage from '../Views/Tecnici/WorkSheets/EditWorksheetPage.vue';
import AddPreventivoPage from '../Views/Segreteria/AddPreventivoPage.vue';
import VediPreventivoPage from '../Views/Segreteria/VediPreventivoPage.vue';
import RicercaInversaPage from '../Views/RicercaContatoriInversa.vue';
import { isLoggedStore } from '@/store/logged';
import crypto from '../services/crypto';
const routes = [
  { path: '/find-client', component: SearchPage, name: 'admin-home' },
  {
    path: '/details/:id/refamm/add',
    component: AddReferentePage,
  },
  {
    path: '/ricerca-inversa',
    component: RicercaInversaPage,
  },
  {
    path: '/aggiungi-preventivo',
    component: AddPreventivoPage,
  },
  {
    path: '/fattura-straordinario/:id',
    component: VediPreventivoPage,
  },
  {
    path: '/details/:id/refamm/:referenteId/edit',
    component: EditReferentePage,
  },
  {
    path: '/contratti',
    component: FiltraContrattiPage,
  },
  {
    path: '/details/proprietario/add',
    component: AddProprietarioPage,
  },
  {
    path: '/details/proprietario/:id/edit',
    component: EditProprietarioPage,
  },
  {
    path: '/filtra-fogli-lavoro',
    component: FilterWorksheetPage,
  },
  {
    path: '/tecnician-home',
    component: TecnicianHomePage,
    name: 'tecnico-home',
  },
  {
    path: '/administration-home',
    component: AdministrationHomePage,
    name: 'segreteria-home',
  },
  {
    path: '/price-calculator/:id',
    component: PriceCalculator,
  },
  {
    path: '/select-client',
    component: SelectClientPage,
  },
  {
    path: '/test/:id',
    name: 'add-worksheet',
    component: AddWorksheetsPage,
  },
  {
    path: '/worksheet/:id/edit',
    name: 'edit-worksheet',
    component: EditWorksheetsPage,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/scadenze',
    component: ScadenzePage,
  },

  {
    path: '/details/:id',
    component: ClientDetailPage,
  },
  {
    path: '/details/:id/contracts/add',
    component: AddContractPage,
  },
  {
    path: '/details/:id/contracts/:contrattoId/edit',
    component: EditContractPage,
  },
  {
    path: '/details/:id/contratto/:contrattoId/centrali/add',
    component: AddCentralePage,
  },
  {
    path: '/details/:id/contratto/:contrattoId/centrali/:centraleId/edit',
    component: EditCentralePage,
  },

  {
    path: '/details/:id/centrale/:centraleId/impianti/add',
    component: AddImpiantiPage,
  },
  {
    path: '/details/:id/centrale/:centraleId/impianti/:impiantiId/edit',
    component: EditImpiantiPage,
  },
  {
    path: '/details/:id/impianto/:impiantoId/sezioni/add',
    component: AddSezionePage,
  },
  {
    path: '/details/:id/impianto/:impiantoId/sezioni/:sezioneId/edit',
    component: EditSezionePage,
  },
  {
    path: '/details/:id/sezione/:sezioneId/inverters/add',
    component: AddInverterPage,
  },
  {
    path: '/details/:id/sezione/:sezioneId/inverters/:inverterId/edit',
    component: EditInverterPage,
  },
  {
    path: '/details/:id/inverter/:inverterId/moduli/add',
    component: AddModuloPage,
  },
  {
    path: '/details/:id/inverter/:inverterId/moduli/:moduloId/edit',
    component: EditModuloPage,
  },
  {
    path: '/error/not-allowed',
    name: 'error',
    component: ErrorePermessiPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const store = isLoggedStore();
  const tipo = crypto.decipher(store.tipo);

  /*
   * Se l'utente non è loggato ritorna a login
   */
  if (
    // make sure the user is authenticated
    (store.isLogged != true || tipo == '' || store.user == '') &&
    // ❗️ Avoid an infinite redirect
    to.name !== 'Login'
  ) {
    // redirect the user to the login page
    return { name: 'Login' };
  }

  /*
   * Imposto la visibilità del menu
   */
  if (
    to.path == '/tecnician-home' ||
    to.path == '/select-client' ||
    to.name == 'add-worksheet' ||
    to.name == 'edit-worksheet' ||
    to.path == '/login'
  ) {
    store.viewMenu = false;
  } else {
    store.viewMenu = true;
  }

  /*
   * Verifico i permessi
   */
  if (to.path.slice(0, 8) == '/details' && tipo != 'admin' && tipo != 'user') {
    return { name: 'error' };
  }
  if (to.path == '/administration-home' && tipo != 'segreteria') {
    return { name: 'error' };
  }
  if (to.path == '/tecnician-home' && tipo != 'tecnico') {
    return { name: 'error' };
  }
  if (to.path == '/contratti' && tipo != 'admin' && tipo != 'user') {
    return { name: 'error' };
  }
  if (to.path == '/scadenze' && tipo != 'admin' && tipo != 'user') {
    return { name: 'error' };
  }
  if (
    to.path == '/filtra-fogli-lavoro' &&
    tipo != 'admin' &&
    tipo != 'user' &&
    tipo != 'segreteria'
  ) {
    return { name: 'error' };
  }

  if (to.path == '/') {
    if (tipo == 'admin') return { name: 'admin-home' };
    if (tipo == 'user') return { name: 'admin-home' };
    if (tipo == 'segreteria') return { name: 'segreteria-home' };
    if (tipo == 'tecnico') return { name: 'tecnico-home' };
  }
});

export default router;
