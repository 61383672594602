<template>
  <h1>Nuova Sezione</h1>

  <el-form :model="sezione" label-width="240px">
    <el-form-item label="Nome Sezione">
      <el-input v-model="sezione.nome" />
    </el-form-item>
    <el-form-item label="KWP Sezione">
      <el-input-number v-model="sezione.kwpsezione" />
    </el-form-item>
    <el-form-item label="GSE">
      <el-input v-model="sezione.gse" />
    </el-form-item>
    <el-form-item label="Data entrata esercizio">
      <el-date-picker
        v-model="sezione.entrataeserciziodata"
        type="date"
        format="DD/MM/YYYY"
        placeholder="Scegli o scrivi un giorno"
      />
    </el-form-item>

    <h3>Contatore</h3>
    <el-form-item label="Marca">
      <el-autocomplete
        v-model="sezione.marcaCont"
        value-key="marca_cont"
        :fetch-suggestions="querySearchMarcaContatore"
        :trigger-on-focus="false"
        clearable
        placeholder="Inserisci Marca Contatore"
        class="autocomplete"
      />
    </el-form-item>
    <el-form-item label="Modello">
      <el-autocomplete
        v-model="sezione.modelloCont"
        value-key="modello_cont"
        :fetch-suggestions="querySearchModelloContatore"
        :trigger-on-focus="false"
        clearable
        placeholder="Inserisci Modello Contatore"
        class="autocomplete"
      />
    </el-form-item>
    <el-form-item label="Matricola">
      <el-input v-model="sezione.matricolaCont" />
    </el-form-item>
    <el-form-item label="SW">
      <el-input v-model="sezione.swMod" />
    </el-form-item>
    <el-form-item label="FW">
      <el-input v-model="sezione.fwMod" />
    </el-form-item>
    <el-form-item label="SIM">
      <el-input v-model="sezione.simCont" />
    </el-form-item>
    <el-form-item label="Scadenza certificato">
      <el-date-picker
        v-model="sezione.scadCertificatoCont"
        type="date"
        format="DD/MM/YYYY"
        placeholder="Scegli o scrivi un giorno"
      />
    </el-form-item>

    <h3>Interfaccia</h3>
    <el-form-item label="Marca">
      <el-autocomplete
        v-model="sezione.marcaInter"
        value-key="marca_inter"
        :fetch-suggestions="querySearchMarcaInterfaccia"
        :trigger-on-focus="false"
        clearable
        placeholder="Inserisci Marca Interfaccia"
        class="autocomplete"
      />
    </el-form-item>
    <el-form-item label="Modello">
      <el-autocomplete
        v-model="sezione.modInter"
        value-key="mod_inter"
        :fetch-suggestions="querySearchModelloInterfaccia"
        :trigger-on-focus="false"
        clearable
        placeholder="Inserisci Modello Contatore"
        class="autocomplete"
      />
    </el-form-item>
    <el-form-item label="Matricola">
      <el-input v-model="sezione.matricolaInter" />
    </el-form-item>
    <el-form-item label="SW">
      <el-input v-model="sezione.swInter" />
    </el-form-item>
    <el-form-item label="FW">
      <el-input v-model="sezione.fwInter" />
    </el-form-item>
    <el-form-item label="Scadenza Certificato">
      <el-date-picker
        v-model="sezione.scadCertificatoInter"
        type="date"
        format="DD/MM/YYYY"
        placeholder="Scegli o scrivi un giorno"
      />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="onSubmit"> Aggiungi </el-button>
      <el-button @click="annulla"> Annulla </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { reactive, toRaw, onMounted } from 'vue';
import detailsAPI from '../../services/detailsAPI';
import searchAPI from '../../services/searchAPI';
import { ElMessage } from 'element-plus';
export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const route = useRoute();
    const impiantoId = route.params.impiantoId;
    const proprietarioId = route.params.id;

    const sezione = reactive({
      nome: '',
      gse: '',
      kwpsezione: 0,
      entrataeserciziodata: new Date(),
      marcaCont: '',
      modelloCont: '',
      matricolaCont: '',
      scadCertificatoCont: new Date(),
      simCont: '',
      swMod: '',
      fwMod: '',
      marcaInter: '',
      modInter: '',
      matricolaInter: '',
      scadCertificatoInter: new Date(),
      swInter: '',
      fwInter: '',
      fkImpianto: impiantoId,
    });

    //Inizializzo i pulsanti
    const onSubmit = () => {
      addNewSezione(toRaw(sezione)).then((response) => {
        console.log(response);
        if (response.status === 201) {
          console.log('Sezione aggiunta!');
          router.push(`/details/${proprietarioId}`);
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const annulla = () => {
      router.push(`/details/${proprietarioId}`);
    };

    // Funzioni per aggiungere nuovo
    const addNewSezione = async (sezione) => {
      if (sezione.nome == '') {
        ElMessage({ message: 'Inserire un nome sezione', type: 'warning' });
        return;
      }
      let response = {};
      try {
        response = await detailsAPI.addSezione(sezione);
        console.log(response);
        return response;
      } catch {
        console.log('error');
      }
    };

    //Autocomplete funzionalities
    let marcaContatore = [];
    let modelloContatore = [];
    let marcaInterfaccia = [];
    let modelloInterfaccia = [];

    const querySearchMarcaContatore = (queryString, cb) => {
      const results = queryString
        ? marcaContatore.filter(createFilterMarcaContatore(queryString))
        : marcaContatore;
      // call callback function to return suggestions
      cb(results);
    };

    const createFilterMarcaContatore = (queryString) => {
      return (item) => {
        return (
          item.marca_cont.toLowerCase().indexOf(queryString.toLowerCase()) == 0
        );
      };
    };

    const querySearchModelloContatore = (queryString, cb) => {
      const results = queryString
        ? modelloContatore.filter(createFilterModelloContatore(queryString))
        : modelloContatore;
      // call callback function to return suggestions
      cb(results);
    };

    const createFilterModelloContatore = (queryString) => {
      return (item) => {
        return (
          item.modello_cont.toLowerCase().indexOf(queryString.toLowerCase()) ==
          0
        );
      };
    };

    const querySearchMarcaInterfaccia = (queryString, cb) => {
      const results = queryString
        ? marcaInterfaccia.filter(createFilterMarcaInterfaccia(queryString))
        : marcaInterfaccia;
      // call callback function to return suggestions
      cb(results);
    };

    const createFilterMarcaInterfaccia = (queryString) => {
      return (item) => {
        return (
          item.marca_inter.toLowerCase().indexOf(queryString.toLowerCase()) == 0
        );
      };
    };

    const querySearchModelloInterfaccia = (queryString, cb) => {
      const results = queryString
        ? modelloInterfaccia.filter(createFilterModelloInterfaccia(queryString))
        : modelloInterfaccia;
      // call callback function to return suggestions
      cb(results);
    };

    const createFilterModelloInterfaccia = (queryString) => {
      return (item) => {
        return (
          item.mod_inter.toLowerCase().indexOf(queryString.toLowerCase()) == 0
        );
      };
    };

    onMounted(() => {
      searchAPI.getAutocompleteSezione().then((result) => {
        marcaContatore = result.data.marcaContatore;
        modelloContatore = result.data.modelloContatore;
        marcaInterfaccia = result.data.marcaInterfaccia;
        modelloInterfaccia = result.data.modelloInterfaccia;
      });
    });
    return {
      proprietarioId,
      sezione,
      onSubmit,
      addNewSezione,
      annulla,
      querySearchMarcaContatore,
      querySearchModelloContatore,
      querySearchModelloInterfaccia,
      querySearchMarcaInterfaccia,
    };
  },
};
</script>
