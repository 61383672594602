<template>
  <h1 class="header-implant">Sezioni</h1>
  <div v-if="sezioni[selectedSection] !== undefined">
    <el-row :gutter="0">
      <el-col :span="4">
        <div class="grid-content" />
        <h4 style="text-align: left; margin-top: 0px">Seleziona Sezione</h4>
        <div id="selection" class="choose-section">
          <el-button
            v-for="(sezione, index) in sezioni"
            :key="sezione.id"
            type="primary"
            class="choose-btn"
            :plain="!(selectedSection == index)"
            @click="selectSection(index)"
          >
            {{ sezione.nome }}
          </el-button>
        </div>
        <div class="action-btn">
          <router-link
            class="routerLink"
            :to="{
              path: `/details/${proprietarioId}/impianto/${impiantoId}/sezioni/add`,
            }"
          >
            <el-button type="success" plain class="choose-btn">
              Aggiungi
            </el-button>
          </router-link>
          <router-link
            class="routerLink"
            :to="{
              path: `/details/${proprietarioId}/impianto/${impiantoId}/sezioni/${sezioni[selectedSection].id}/edit`,
            }"
          >
            <el-button type="info" plain class="choose-btn">
              Modifica
            </el-button>
          </router-link>
        </div>
      </el-col>
      <el-col :span="20">
        <el-descriptions title="Dettagli Sezione" :column="3" border>
          <el-descriptions-item
            label="Nome sezione"
            label-align="right"
            align="left"
            label-class-name="my-label"
            class-name="my-content"
            :span="3"
          >
            {{ sezioni[selectedSection].nome }}
          </el-descriptions-item>

          <el-descriptions-item
            label="KWP Sezione"
            label-align="right"
            align="left"
          >
            {{ sezioni[selectedSection].kwpsezione }}
          </el-descriptions-item>
          <el-descriptions-item label="GSE" label-align="right" align="left">
            {{ sezioni[selectedSection].gse }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Data entrata servizio"
            label-align="right"
            align="left"
          >
            {{ showDate(sezioni[selectedSection].entrataeserciziodata) }}
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions title="Contatore" :column="3" border>
          <el-descriptions-item label="Marca" label-align="right" align="left">
            {{ sezioni[selectedSection].marcaCont }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Modello"
            label-align="right"
            align="left"
          >
            {{ sezioni[selectedSection].modelloCont }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Matricola"
            label-align="right"
            align="left"
          >
            {{ sezioni[selectedSection].matricolaCont }}
          </el-descriptions-item>
          <el-descriptions-item label="SW" label-align="right" align="left">
            {{ sezioni[selectedSection].swMod }}
          </el-descriptions-item>
          <el-descriptions-item label="FW" label-align="right" align="left">
            {{ sezioni[selectedSection].fwMod }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Scadenza certificato"
            label-align="right"
            align="left"
          >
            {{ showDate(sezioni[selectedSection].scadCertificatoCont) }}
          </el-descriptions-item>
          <el-descriptions-item
            label="SIM"
            label-align="right"
            align="left"
            :span="3"
          >
            {{ sezioni[selectedSection].simCont }}
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions title="Interfaccia" :column="3" border>
          <el-descriptions-item label="Marca" label-align="right" align="left">
            {{ sezioni[selectedSection].marcaInter }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Modello"
            label-align="right"
            align="left"
          >
            {{ sezioni[selectedSection].modInter }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Matricola"
            label-align="right"
            align="left"
          >
            {{ sezioni[selectedSection].matricolaInter }}
          </el-descriptions-item>
          <el-descriptions-item label="SW" label-align="right" align="left">
            {{ sezioni[selectedSection].swInter }}
          </el-descriptions-item>
          <el-descriptions-item label="FW" label-align="right" align="left">
            {{ sezioni[selectedSection].fwInter }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Scadenza certificato"
            label-align="right"
            align="left"
          >
            {{ showDate(sezioni[selectedSection].scadCertificatoInter) }}
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>

    <InvertersRead
      :sezione-id="sezioni[selectedSection].id"
      :inverters="inverters"
    />
  </div>
  <div v-else>
    <router-link
      class="routerLink"
      :to="{
        path: `/details/${proprietarioId}/impianto/${impiantoId}/sezioni/add`,
      }"
    >
      <el-button type="success" plain class="choose-btn"> Aggiungi </el-button>
    </router-link>
  </div>
</template>

<script>
import InvertersRead from './InvertersRead.vue';
import { ref } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'SezioniRead',
  components: { InvertersRead },
  props: {
    sezioni: { type: Array, default: () => [{ inverters: [] }] },
    impiantoId: { type: Number, default: () => 0 },
  },
  setup() {
    let selectedSection = ref(0);
    let selectSection = (index) => {
      selectedSection.value = index;
    };
    let route = useRoute();
    const proprietarioId = route.params.id;

    let showDate = (date) => {
      return new Date(date).toLocaleString('it-IT');
    };
    return {
      selectedSection,
      selectSection,
      proprietarioId,
      showDate,
    };
  },
  computed: {
    inverters() {
      return this.sezioni[this.selectedSection].inverters;
    },
  },
};
</script>

<style></style>
