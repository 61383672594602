import API from './API';
import axios from 'axios';

export default {
  //produzione https://gestionale-scintilla.com/apis/v1/proprietario/upload
  upload(formData) {
    return axios.post(
      'https://gestionale-scintilla.com/apis/v1/proprietario/upload',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },
  addReferente(referente) {
    return API().post('/ref-amm', referente);
  },
  getReferente(id) {
    return API().get('/ref-amm/' + id);
  },
  patchReferente(id, referente) {
    return API().patch('/ref-amm/' + id, referente);
  },
  editProprietario(id, proprierario) {
    return API().patch('/proprietario/' + id, proprierario);
  },
  getSimpleProprietario(id) {
    return API().get('/proprietario/' + id + '/simple');
  },
  addProprietario(proprietario) {
    return API().post('/proprietario', proprietario);
  },
  sendMail(worksheetId, to) {
    return API().post('/worksheet/test-mail', {
      worksheetId: worksheetId,
      to: to,
    });
  },
  loadPriceItems(id) {
    return API().get('/worksheet/price-calculator/' + id);
  },

  loadNotInvoicedWorksheet() {
    return API().get('/worksheet/not-invoiced');
  },

  invoiceWorksheet(id) {
    return API().patch('/worksheet/' + id, { fatturato: 1 });
  },

  getWorksheetData(id) {
    return API().get('/proprietario/worksheet-data/' + id);
  },
  getWorksheetDetails(id) {
    return API().get('/worksheet/full-one/' + id);
  },
  addWorksheet(worksheet) {
    console.log(worksheet)
    return API().post('/worksheet', worksheet);
  },
  editWorksheet(id, worksheet) {
    return API().put('worksheet/' + id, worksheet);
  },

  //produzione https://gestionale-scintilla.com/apis/v1/proprietario/upload/
  display(path) {
    return axios.get(
      `https://gestionale-scintilla.com/apis/v1/proprietario/upload/${path.replace(
        '/',
        '_'
      )}`,
      {
        headers: {
          Accept: 'application/pdf',
        },
        responseType: 'blob',
      }
    );
  },

  getDetails(id) {
    return API().get('/proprietario/full-one/' + id);
  },
  addPreventivo(preventivo) {
    return API().post('/preventivo', preventivo);
  },
  getPreventivo(preventivo) {
    return API().get('/preventivo/' + preventivo);
  },
  addContract(contract) {
    return API().post('/contratto', contract);
  },
  patchWorksheet(id, worksheet) {
    return API().patch('/worksheet/' + id, worksheet);
  },
  getContract(id) {
    return API().get('/contratto/' + id);
  },
  patchContract(id, contract) {
    return API().patch('/contratto/' + id, contract);
  },
  addCentrale(centrale) {
    return API().post('/centrale', centrale);
  },
  getCentrale(id) {
    return API().get('/centrale/' + id);
  },
  patchCentrale(id, centrale) {
    return API().patch('/centrale/' + id, centrale);
  },
  addImpianto(impianto) {
    return API().post('/impianto', impianto);
  },
  getImpianto(id) {
    return API().get('/impianto/' + id);
  },
  patchImpianto(id, impianto) {
    return API().patch('/impianto/' + id, impianto);
  },
  addSezione(sezione) {
    return API().post('/sezione', sezione);
  },
  getSezione(id) {
    return API().get('/sezione/' + id);
  },
  patchSezione(id, sezione) {
    return API().patch('/sezione/' + id, sezione);
  },
  addInverter(inverter) {
    return API().post('/inverter', inverter);
  },
  getInverter(id) {
    return API().get('/inverter/' + id);
  },
  patchInverter(id, inverter) {
    return API().patch('/inverter/' + id, inverter);
  },
  addModulo(modulo) {
    return API().post('/modulo', modulo);
  },
  getModulo(id) {
    return API().get('/modulo/' + id);
  },
  patchModulo(id, modulo) {
    return API().patch('/modulo/' + id, modulo);
  },
  loadDraftWorksheets(username) {
    return API().get(
      'worksheet?select=id,date,proprietario&filter=inviato||$eq||0&filter=fkUser||$eq||' +
        username
    );
  },
  loadLastWorksheets(username) {
    return API().get(
      'worksheet?select=id,date,proprietario&filter=inviato||$ne||0&filter=fkUser||$eq||' +
        username +
        '&sort=date,DESC&limit=50'
    );
  },
  loadToInvoceWorksheets() {
    return API().get(
      'worksheet?filter=inviato||$eq||1&filter=manutenzioneStraordinaria||$eq||1'
    );
  },

  //produzione https://gestionale-scintilla.com/apis/v1/worksheet/generate-worksheets/
  printWorksheet(id) {
    console.log(id);
    try {
      axios({
        url:
          'https://gestionale-scintilla.com/apis/v1/worksheet/generate-worksheets/' +
          id,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' })
        );
        var newWin = window.open(fileURL);
        newWin.focus();
      });
    } catch (err) {
      console.log(err);
    }
  },
};
