<template>
  <div v-if="refAmm[selectedRef] !== undefined">
    <h1 class="header-implant">Referenti</h1>

    <el-row :gutter="0">
      <el-col :span="4">
        <div class="grid-content" />
        <h4 style="text-align: left; margin-top: 0px">Seleziona referente</h4>
        <div id="selection" class="choose-section">
          <el-button
            v-for="(referente, index) in refAmm"
            :key="referente.id"
            type="primary"
            class="choose-btn"
            :plain="!(selectedRef == index)"
            @click="selectRef(index)"
          >
            {{ referente.nome }} {{ referente.cognome }}
          </el-button>
        </div>
        <div class="action-btn">
          <router-link
            class="routerLink"
            :to="{ path: `/details/${proprietarioId}/refamm/add` }"
          >
            <el-button type="success" plain class="choose-btn">
              Aggiungi
            </el-button>
          </router-link>
          <router-link
            class="routerLink"
            :to="{
              path: `/details/${proprietarioId}/refamm/${refAmm[selectedRef].id}/edit`,
            }"
          >
            <el-button type="info" plain class="choose-btn">
              Modifica
            </el-button>
          </router-link>
        </div>
      </el-col>
      <el-col :span="20">
        <el-descriptions title="Dettagli referente" :column="3" border>
          <el-descriptions-item
            label="Nome"
            label-align="right"
            align="left"
            label-class-name="my-label"
            class-name="my-content"
          >
            {{ refAmm[selectedRef].nome }}
          </el-descriptions-item>

          <el-descriptions-item
            label="Cognome"
            label-align="right"
            align="left"
          >
            {{ refAmm[selectedRef].cognome }}
          </el-descriptions-item>

          <el-descriptions-item label="Mail" label-align="right" align="left">
            {{ refAmm[selectedRef].mail }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Telefono Fisso"
            label-align="right"
            align="left"
          >
            {{ refAmm[selectedRef].fisso }}
          </el-descriptions-item>
          <el-descriptions-item
            label="Cellulare"
            label-align="right"
            align="left"
          >
            {{ refAmm[selectedRef].cell }}
          </el-descriptions-item>
          <el-descriptions-item label="Note" label-align="right" align="left">
            {{ refAmm[selectedRef].note }}
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>
  </div>
  <div v-else>
    <router-link
      class="routerLink"
      :to="{ path: `/details/${proprietarioId}/refamm/add` }"
    >
      <el-button type="success" plain class="choose-btn">
        Aggiungi Referenti</el-button
      >
    </router-link>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
export default {
  name: 'RefAmmRead',
  props: {
    refAmm: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    let selectedRef = ref(0);
    let selectRef = (index) => {
      selectedRef.value = index;
    };
    const route = useRoute();
    const proprietarioId = route.params.id;

    return {
      selectedRef,
      selectRef,
      proprietarioId,
    };
  },
};
</script>

<style>
.choose-section {
  width: 100%;
}
.choose-btn {
  width: 98%;
  height: 30px;
  padding: auto;
  margin-left: 0px !important;
}

.header-implant {
  margin-top: 100px;
}

.action-btn {
  margin-top: 30px;
}
</style>
