<template>
  <h1>Modifica {{ sezione.nome }}</h1>

  <el-form
    :model="sezione"
    label-width="240px"
  >
    <el-form-item label="Nome Sezione">
      <el-input v-model="sezione.nome" />
    </el-form-item>
    <el-form-item label="KWP Sezione">
      <el-input-number v-model="sezione.kwpsezione" />
    </el-form-item>
    <el-form-item label="GSE">
      <el-input v-model="sezione.gse" />
    </el-form-item>
    <el-form-item label="Data entrata esercizio">
      <el-date-picker
        v-model="sezione.entrataeserciziodata"
        type="date"
        format="DD/MM/YYYY"
        placeholder="Scegli o scrivi un giorno"
      />
    </el-form-item>

    <h3>Contatore</h3>
    <el-form-item label="Marca">
      <el-input v-model="sezione.marcaCont" />
    </el-form-item>
    <el-form-item label="Modello">
      <el-input v-model="sezione.modelloCont" />
    </el-form-item>
    <el-form-item label="Matricola">
      <el-input v-model="sezione.matricolaCont" />
    </el-form-item>
    <el-form-item label="SW">
      <el-input v-model="sezione.swMod" />
    </el-form-item>
    <el-form-item label="FW">
      <el-input v-model="sezione.fwMod" />
    </el-form-item>
    <el-form-item label="SIM">
      <el-input v-model="sezione.simCont" />
    </el-form-item>
    <el-form-item label="Scadenza certificato">
      <el-date-picker
        v-model="sezione.scadCertificatoCont"
        type="date"
        format="DD/MM/YYYY"
        placeholder="Scegli o scrivi un giorno"
      />
    </el-form-item>

    <h3>Interfaccia</h3>
    <el-form-item label="Marca">
      <el-input v-model="sezione.marcaInter" />
    </el-form-item>
    <el-form-item label="Modello">
      <el-input v-model="sezione.modInter" />
    </el-form-item>
    <el-form-item label="Matricola">
      <el-input v-model="sezione.matricolaInter" />
    </el-form-item>
    <el-form-item label="SW">
      <el-input v-model="sezione.swInter" />
    </el-form-item>
    <el-form-item label="FW">
      <el-input v-model="sezione.fwInter" />
    </el-form-item>
    <el-form-item label="Scadenza Certificato">
      <el-date-picker
        v-model="sezione.scadCertificatoInter"
        type="date"
        format="DD/MM/YYYY"
        placeholder="Scegli o scrivi un giorno"
      />
    </el-form-item>

    <el-form-item>
      <el-button
        type="primary"
        @click="onSubmit"
      >
        Modifica
      </el-button>
      <el-button @click="annulla">
        Annulla
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { useRoute, useRouter } from 'vue-router';
import { onMounted, reactive, toRaw } from 'vue';
import detailsAPI from '../../services/detailsAPI';

export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const route = useRoute();
    const proprietarioId = route.params.id;
    const sezioneId = route.params.sezioneId;

    const sezione = reactive({
      id: 0,
      nome: '',
      gse: '',
      kwpsezione: 0,
      entrataeserciziodata: new Date(),
      marcaCont: '',
      modelloCont: '',
      matricolaCont: '',
      scadCertificatoCont: new Date(),
      simCont: '',
      swMod: '',
      fwMod: '',
      marcaInter: '',
      modInter: '',
      matricolaInter: '',
      scadCertificatoInter: new Date(),
      swInter: '',
      fwInter: '',
      fkImpianto: 0,
    });

    //Inizializzo i pulsanti
    const onSubmit = () => {
      editSezione(sezioneId, toRaw(sezione)).then((response) => {
        if (response.status === 200) {
          console.log('Sezione modificata!');
          router.push(`/details/${proprietarioId}`);
        } else {
          console.log('errore inatteso');
        }
      });
    };

    const annulla = () => {
      router.push(`/details/${proprietarioId}`);
    };

    //Riempio una volta xaricata la pagina
    const getSezione = async (id) => {
      let response = {};
      try {
        console.log(id);
        response = await detailsAPI.getSezione(id);
        return response.data;
      } catch {
        console.log('error');
      }
    };

    onMounted(() => {
      getSezione(sezioneId).then((sezioneDetail) => {
        if (sezioneDetail) {
          Object.assign(sezione, sezioneDetail);
          console.log(sezione);
        }
      });
    });

    // Funzioni per modificare
    const editSezione = async (id, sezione) => {
      let response = {};
      try {
        response = await detailsAPI.patchSezione(id, sezione);
        return response;
      } catch {
        console.log('error');
      }
    };

    return {
      proprietarioId,
      sezione,
      onSubmit,
      annulla,
    };
  },
};
</script>
